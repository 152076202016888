/* istanbul ignore file */

import React, { FC } from 'react'
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { ModerationPage } from './pages/ModerationPage'
import { LoginPage } from './pages/LoginPage'
import { UserData, useLogin } from './providers/LoginProvider'
import { MobileModerationPage } from './pages/MobileModerationPage'
import { BannedUsersPage } from './pages/BannedUsersPage'
import { routes } from './constants/routes'
import { AdminUsersPage } from './pages/AdminUsersPage'
import { ModerationStateProvider } from './providers/ModerationStateProvider'
import { maxWidth } from './utils/mediaQuery'
import { isCommentator, isModerator, isOperator } from './utils/user'

const getDefaultRoute = (user: UserData) => {
  if (isModerator(user) || isCommentator(user)) {
    return routes.moderation
  } else if (isOperator(user)) {
    return routes.adminUsers
  }
  return routes.moderation
}

const AuthRoutes: FC = () => {
  const isMobile = useMediaQuery(maxWidth('lg'))
  const { user } = useLogin()

  return (
    <ModerationStateProvider>
      <RouterRoutes>
        <Route path={routes.moderation} element={<ModerationPage />} />
        <Route path={routes.adminUsers} element={<AdminUsersPage />} />
        <Route path={routes.bannedUsers} element={<BannedUsersPage />} />
        <Route
          path={`${routes.moderation}/:topicId`}
          element={isMobile ? <MobileModerationPage /> : <ModerationPage />}
        />
        <Route path="*" element={<Navigate to={getDefaultRoute(user)} replace />} />
      </RouterRoutes>
    </ModerationStateProvider>
  )
}

export const Routes: FC = () => {
  const { user } = useLogin()
  return user.loggedIn ? <AuthRoutes /> : <LoginPage />
}
