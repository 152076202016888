/* istanbul ignore file */
import React, { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { CssBaseline } from '@mui/material'

import { SnackbarProvider } from './components/SnackBar/SnackbarProvider'
import { MuiThemeProvider } from './providers/MuiThemeProvider'
import { ApiOptionsProvider, Env } from './providers/ApiOptionsProvider'
import { LoginProvider } from './providers/LoginProvider'
import { APP_LOCALE, DateFnsLocales } from './utils/localization'
import { Routes } from './Routes'

type AppProps = {
  env: Env
}

export const App: FC<AppProps> = ({ env }) => (
  <MuiThemeProvider>
    <CssBaseline />
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={DateFnsLocales[APP_LOCALE]}>
      <ApiOptionsProvider env={env}>
        <SnackbarProvider>
          <LoginProvider>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </LoginProvider>
        </SnackbarProvider>
      </ApiOptionsProvider>
    </LocalizationProvider>
  </MuiThemeProvider>
)
