import React, { FC } from 'react'
import { Button, Checkbox, TableCell, TableRow } from '@mui/material'
import type { UserBlock } from '../../models/user'
import { formatTimestamp } from '../../utils/date-utils'

type BannedUserListItemProps = {
  userBlock: UserBlock
  isSelected: boolean
  toggleSelected: () => void
  removeUserBlock: () => void
}

export const BannedUserListItem: FC<BannedUserListItemProps> = ({
  userBlock,
  isSelected,
  toggleSelected,
  removeUserBlock
}) => (
  <TableRow key={userBlock.user.id}>
    <TableCell padding="checkbox" sx={{ paddingLeft: '15px' }}>
      <Checkbox checked={isSelected} onChange={toggleSelected} />
    </TableCell>
    <TableCell>{userBlock.user.nick}</TableCell>
    <TableCell>{userBlock.block?.reason}</TableCell>
    <TableCell>{formatTimestamp(userBlock.block?.createdAt)}</TableCell>
    <TableCell>{userBlock.block.blockingUserNick}</TableCell>
    <TableCell sx={{ textAlign: 'right' }}>
      <Button onClick={removeUserBlock} sx={{ ml: 3, mr: 1 }} variant="outlined" size="small">
        Unban
      </Button>
    </TableCell>
  </TableRow>
)
