import React, { FC, ReactNode } from 'react'
import { createContext } from '../utils/context'
import { useGoogleAuth } from '../utils/google-auth'

export type BackendUser = {
  isModerator: boolean
  isOperator: boolean
  isCommentator: boolean
}

export type LoadingUser = {
  type: 'loading'
  loggedIn: false
}

export type ErrorUser = {
  type: 'error'
  loggedIn: false
  error: Error
}

export type LoggedOutUser = {
  type: 'loggedOut'
  loggedIn: false
}

export type LoggedInUser = BackendUser & {
  type: 'loggedIn'
  loggedIn: true
  id: string
  firstName: string
  lastName: string
  email: string
  photoUrl: string
}

export type UserData = LoadingUser | ErrorUser | LoggedOutUser | LoggedInUser

export type LoginData = {
  user: UserData
  token?: string
  logout(): void
}

const [useLogin, Provider] = createContext<LoginData>('LoginContext')
export { useLogin }
//exported for testing
export { Provider as TestLoginProvider }

export const LoginProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const loginData = useGoogleAuth()
  return <Provider value={loginData}>{children}</Provider>
}
