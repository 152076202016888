/* eslint-env browser */
/* istanbul ignore file */
import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import type { Env } from './providers/ApiOptionsProvider'

declare const env: Env

const container = document.getElementById('app')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(<App env={env} />)
