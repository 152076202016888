import React, { FC, useState } from 'react'
import { Typography, TextField, Button } from '@mui/material'
import { Block, User, UserBlock } from '../../models/user'
import { Modal } from '../Modal'
import { UserSearch } from '../UserSearch'
import { useBlock } from '../../hooks/block'
import { useUser } from '../../hooks/user'
import { useUserCommentStats } from '../../hooks/comments'
import { useEndpoint } from '../../hooks/endpoint'
import { createBlock, searchUsers } from '../../api/comments-api'
import { formatTimestamp } from '../../utils/date-utils'

type CommentsStatsProps = {
  userId: string
}

/* Display some stats about the users comments */
const CommentsStats: FC<CommentsStatsProps> = ({ userId }) => {
  const { accepted, rejected, unmoderated } = useUserCommentStats({ userId })

  return (
    <>
      <Typography variant="subtitle1">Accepted messages: {accepted}</Typography>
      <Typography variant="subtitle1">Rejected messages: {rejected}</Typography>
      <Typography variant="subtitle1">Unmoderated messages: {unmoderated}</Typography>
    </>
  )
}

type BlockExplanationProps = {
  block: Block
}

/* Display information about the block */
const BlockExplanation: FC<BlockExplanationProps> = ({ block }) => {
  const blockedBy = useUser({ userId: block?.blockingUserId })
  const blockDate = formatTimestamp(block?.createdAt)

  return (
    <Typography variant="subtitle1" color={'error.main'} sx={{ mt: 2 }}>
      User has been banned {blockDate} by {blockedBy?.nick}
    </Typography>
  )
}

type BlockModalProps = {
  open: boolean
  handleClose: () => void
  onSuccess: (userBlock: UserBlock) => void
  authorId?: string
  author?: string
}

const BlockModalComponent: FC<BlockModalProps> = ({
  open,
  handleClose,
  onSuccess,
  authorId,
  author
}) => {
  const [selectedUser, setSelectedUser] = useState<string | undefined>(authorId)
  const user = useUser({ userId: selectedUser })
  const block = useBlock({ userId: selectedUser })
  const [newBlockReason, setNewBlockReason] = useState<string>('')
  const createUserBlock = useEndpoint(createBlock)
  const search = useEndpoint(searchUsers)

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title="Ban User"
      actions={
        <Button
          onClick={() => {
            if (user) {
              createUserBlock({ userId: user.id, reason: newBlockReason }).then(onSuccess)
            }
          }}
          sx={{ ml: 2 }}
          variant="contained"
          size="small"
          disabled={!user || user.isBanned || newBlockReason === ''}
        >
          Ban User
        </Button>
      }
    >
      <UserSearch
        defaultValue={author || ''}
        label="Search for user"
        onSelect={user => setSelectedUser(user?.id)}
        onSearch={nickname => search({ nickname })}
        getOptionLabel={(option: string | User) => {
          if (typeof option === 'string') {
            return option
          } else {
            return option.nick
          }
        }}
        renderOption={(props, user) => {
          const key = `${user.id}`
          return (
            <li {...props} key={key}>
              {user.nick}
            </li>
          )
        }}
      />
      {user && (
        <Typography sx={{ mb: 2 }} variant="h5">
          {user.nick}
        </Typography>
      )}
      {user && <CommentsStats userId={user.id} />}
      {block && <BlockExplanation block={block} />}
      <Typography variant="h6" component="h2" sx={{ mt: 2, mb: 2 }}>
        Reason for banning
      </Typography>
      <TextField
        multiline
        disabled={!user || typeof block !== 'undefined'}
        label="Reason for banning"
        fullWidth
        rows={2}
        InputLabelProps={{
          shrink: true
        }}
        value={block?.reason || newBlockReason}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setNewBlockReason(event.target.value)
        }}
      />
    </Modal>
  )
}

export const BlockModal: FC<BlockModalProps> = params => {
  // Using a parent component for the conditional rendering.
  // This way the hooks are not run unless the modal is open.
  if (params.open) {
    return <BlockModalComponent {...params} />
  } else {
    return null
  }
}
