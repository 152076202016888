import { useEffect, useState } from 'react'
import { getUserBlocks } from '../api/comments-api'
import type { UserBlock } from '../models/user'
import { useEndpoint } from './endpoint'

export type AppendUserBlocks = (userBlocks: UserBlock[]) => void

export type RemoveUserBlocks = (userIds: string[]) => void

export const useUserBlocks = (): {
  userBlocks: UserBlock[]
  appendUserBlocks: AppendUserBlocks
  removeUserBlocks: RemoveUserBlocks
} => {
  const [userBlocks, setUserBlocks] = useState<UserBlock[]>([])
  const getItems = useEndpoint(getUserBlocks)

  useEffect(() => {
    getItems().then(setUserBlocks)
  }, [getItems])

  const appendUserBlocks = (newUserBlocks: UserBlock[]) => {
    setUserBlocks([...newUserBlocks, ...userBlocks])
  }

  const removeUserBlocks = (userIds: string[]) => {
    setUserBlocks(userBlocks.filter(userBlock => !userIds.includes(userBlock.user.id)))
  }

  return { userBlocks, appendUserBlocks, removeUserBlocks }
}
