import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { FC } from 'react'

type ConfirmDialogProps = {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  title: string
  text: string
  confirmText: string
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  onConfirm,
  onCancel,
  title,
  text,
  confirmText
}) => (
  <Dialog open={open} disablePortal>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{text}</DialogContent>
    <DialogActions>
      <Button onClick={onCancel} variant="outlined" color="primary">
        Cancel
      </Button>
      <Button onClick={onConfirm} variant="contained" color="error">
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
)
