import React, { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { AreenaTopicModal } from '../components/AreenaTopicModal/AreenaTopicModal'
import { CommentList } from '../components/Comment/CommentList'
import { Navigation } from '../components/Navigation/Navigation'
import { useNavigate, useParams } from 'react-router-dom'
import { useLogin } from '../providers/LoginProvider'
import { useModerationState } from '../providers/ModerationStateProvider'
import { Topic } from '../models/topic'
import { isOperator } from '../utils/user'

export const MobileModerationPage: FC = () => {
  const { topicId } = useParams()
  const { user } = useLogin()
  const {
    topicsAndComments: {
      topics,
      selectedTopic,
      loadSingleTopic,
      comments,
      notices,
      actions,
      tab,
      setTab,
      loading
    },
    automoderationLimits
  } = useModerationState()
  const [isAreenaTopicModalOpen, setIsAreenaTopicModalOpen] = useState(false)
  const [editedAreenaTopic, setEditedAreenaTopic] = useState<Topic>()

  const navigate = useNavigate()

  useEffect(() => {
    if (topicId) {
      loadSingleTopic(topicId)
    }
  }, [loadSingleTopic, topicId])

  return (
    <Navigation currentRoute="moderation">
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1 }}>
        <CommentList
          topic={topics.find(t => t.id === selectedTopic)}
          automoderationLimits={automoderationLimits}
          comments={comments}
          notices={notices}
          actions={actions}
          tab={tab}
          setTab={setTab}
          loading={loading}
          editTopic={
            isOperator(user)
              ? (topic: Topic) => {
                  setEditedAreenaTopic(topic)
                  setIsAreenaTopicModalOpen(true)
                }
              : undefined
          }
        />
      </Box>
      <AreenaTopicModal
        open={isAreenaTopicModalOpen}
        topic={editedAreenaTopic}
        handleClose={() => {
          setEditedAreenaTopic(undefined)
          setIsAreenaTopicModalOpen(false)
        }}
        navigate={navigate}
      />
    </Navigation>
  )
}
