import React, { FC } from 'react'
import { styled } from '@mui/system'
import { CircularProgress } from '@mui/material'
import { Navigation } from '../components/Navigation/Navigation'
import { PageInstructions } from '../components/PageInstructions'
import { useLogin } from '../providers/LoginProvider'
import { GoogleLoginButton } from '../utils/google-auth'
import { isLoading } from '../utils/user'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: theme.spacing(2)
}))

export const LoginPage: FC = () => {
  const { user } = useLogin()

  return (
    <Navigation>
      <Container>
        {isLoading(user) ? (
          <CircularProgress />
        ) : (
          <>
            <PageInstructions text="Log in with your Yle Google account" />
            <GoogleLoginButton />
          </>
        )}
      </Container>
    </Navigation>
  )
}
