import React, { FC, MouseEventHandler, ReactNode } from 'react'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { Menu } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { useLogin } from '../../providers/LoginProvider'
import { GoogleAvatar } from './GoogleAvatar'

type TopBarProps = {
  titleText?: string
  titleItem?: ReactNode
  onClickMenu: MouseEventHandler<HTMLButtonElement>
}

export const TopBar: FC<TopBarProps> = ({ titleText = '', titleItem, onClickMenu }) => {
  const { user, logout } = useLogin()
  const theme = useTheme()

  return (
    <AppBar
      position="fixed"
      sx={{
        display: { xs: 'block', sm: 'block', md: 'none' },
        zIndex: theme => theme.zIndex.drawer + 1,
        background: theme.palette.primary.dark
      }}
    >
      <Toolbar>
        <IconButton
          sx={{ display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' } }}
          color="inherit"
          size="large"
          onClick={onClickMenu}
          aria-label="Open drawer"
        >
          <Menu />
        </IconButton>
        {titleText && <Typography sx={{ flexGrow: 1 }}>{titleText}</Typography>}
        {titleItem}
        <GoogleAvatar user={user} logout={logout} />
      </Toolbar>
    </AppBar>
  )
}
