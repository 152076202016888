import { Tag } from '../models/tag'

export const sum = (nums: number[]): number => nums.reduce((x, y) => x + y, 0)

export const notNull = <T>(t: T | null | undefined): t is T => t != null

export const groupBy = <T, K extends string | number | symbol | null | undefined>(
  xs: T[],
  f: (x: T) => K
): Record<NonNullable<K>, T[]> => {
  const ret = {} as Record<NonNullable<K>, T[]>
  for (const x of xs) {
    const k = f(x)
    if (k != null) {
      const arr = ret[k] ?? []
      arr.push(x)
      ret[k] = arr
    }
  }
  return ret
}

export const isAreenaTag = (tag: Tag) => tag.externalId === 'areena' || tag.externalId === 'arenan'

export const difference = <T>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const diff = new Set(setA)
  for (const elem of setB) {
    diff.delete(elem)
  }
  return diff
}
