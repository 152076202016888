import React, { FC } from 'react'
import { Box, ListItem, ListItemButton, Typography } from '@mui/material'
import { Topic } from '../../models/topic'
import { formatRelativeTimestamp } from '../../utils/date-utils'
import { palette } from '../../styles/colors'

export const getTag = (topic: Topic): string => {
  if (topic.tags.length > 1 && topic.tags[0].externalId === 'svenska-yle-fi') {
    return topic.tags[1].name
  } else if (topic.tags.length > 0) {
    return topic.tags[0].name
  }
  return ''
}

type DetailsProps = {
  count: number
  date: Date | null
  isNotice: boolean
}

const Details: FC<DetailsProps> = ({ count, date, isNotice }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: '36px'
    }}
  >
    {count > 0 && (
      <Box
        sx={{
          borderRadius: 9999,
          backgroundColor: isNotice ? 'warning.main' : 'primary.light',
          color: 'black',
          minHeight: '18px',
          minWidth: '18px',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '12px',
          marginBottom: '2px'
        }}
      >
        {count}
      </Box>
    )}
    {date !== null && (
      <Typography
        noWrap
        sx={{
          fontSize: '12px',
          fontWeight: '500'
        }}
      >
        {formatRelativeTimestamp(date)}
      </Typography>
    )}
  </Box>
)

type TopicListItemProps = {
  topic: Topic
  isSelected: boolean
  onClick?: () => void
}

export const TopicListItem: FC<TopicListItemProps> = ({ topic, isSelected, onClick }) => (
  <ListItem divider={true} disablePadding>
    <ListItemButton
      sx={{
        '&.Mui-selected': {
          backgroundColor: 'primary.dark',
          color: 'white',
          '&:hover': { backgroundColor: 'primary.main' }
        },
        backgroundColor:
          topic.unmoderatedCommentsCount === 0 && topic.unprocessedNoticesCount === 0
            ? palette.grey.lighter
            : undefined,
        paddingLeft: 3,
        paddingRight: 3
      }}
      selected={isSelected}
      onClick={onClick}
    >
      <Typography component="span" sx={{ fontSize: '2', lineHeight: '20px', flex: 1 }}>
        {topic.title}{' '}
        <Typography
          component="span"
          sx={{
            textTransform: 'uppercase',
            whiteSpace: 'noWrap',
            fontSize: '12px',
            color: isSelected ? '#CCC2DC' : '#625B71'
          }}
        >
          {getTag(topic)}
        </Typography>
      </Typography>
      <Details
        count={topic.unmoderatedCommentsCount + topic.unprocessedNoticesCount}
        date={topic.oldestOpenModerationTaskCreatedAt}
        isNotice={topic.unprocessedNoticesCount > 0}
      />
    </ListItemButton>
  </ListItem>
)
