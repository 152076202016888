import React, { FC } from 'react'
import { Chip } from '@mui/material'
import { Add, Check } from '@mui/icons-material'

type ToggleProps = {
  selected: boolean
  label: string
  onClick: () => void
}

export const Toggle: FC<ToggleProps> = ({ selected, label, onClick }) => (
  <Chip
    icon={
      selected ? (
        <Check sx={{ width: '16px', height: '16px' }} />
      ) : (
        <Add sx={{ width: '16px', height: '16px' }} />
      )
    }
    variant={selected ? 'filled' : 'outlined'}
    label={label}
    color="primary"
    onClick={onClick}
    aria-checked={selected}
    role="checkbox"
    sx={{ '.MuiTouchRipple-root': { display: 'none' } }}
  />
)
