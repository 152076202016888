import { useCallback } from 'react'
import { ApiOptions, useApiOptions } from '../providers/ApiOptionsProvider'
import { useLogin } from '../providers/LoginProvider'

export const useEndpoint = <T extends unknown[], U>(
  endpoint: (...args: [...T, ApiOptions, string]) => Promise<U>
) => {
  const apiOptions = useApiOptions()
  const { token } = useLogin()
  return useCallback(
    (...args: T): Promise<U> => {
      if (!token) {
        throw Error('Failed to call endpoint, missing token')
      }
      return endpoint(...args, apiOptions, token)
    },
    [apiOptions, token, endpoint]
  )
}
