import React, { FC, ReactNode } from 'react'
import { Box, Button, Chip, ListItem, SxProps, Typography } from '@mui/material'
import { Comment } from '../../models/comment'
import { formatDistanceToNow } from 'date-fns'
import { isModerator } from '../../utils/user'
import { notNull } from '../../utils/util'
import { Notice } from '../../models/notice'
import { Quote } from './Quote'
import { BlockModal } from '../BlockModal/BlockModal'
import { useLogin } from '../../providers/LoginProvider'
import { ModerationInfo } from './ModerationInfo'
import { AiModeration } from '../AiModeration/AiModeration'
import { AutomoderationLimits } from '../../models/automoderationLimits'

export type RenderComment = Comment & {
  quote: Comment | null
  notices: Notice[] | null
}

export type CommentListItemAction = {
  onClick: (comment: RenderComment) => void
  text: string
  sx?: SxProps
  variant: 'contained' | 'text' | 'outlined'
  startIcon?: ReactNode
}

type CommentListItemProps = {
  comment: RenderComment
  commentIcon: ReactNode | null
  onFilter: (searchTerm: string) => void
  actions: (CommentListItemAction | null)[]
  tree: boolean
  automoderationLimits: AutomoderationLimits | null
}

const automaticModerator = 'Automatic Moderator'

export const CommentListItem: FC<CommentListItemProps> = ({
  comment,
  commentIcon,
  onFilter,
  automoderationLimits,
  actions,
  tree
}) => {
  const [showBlockUser, setShowBlockUser] = React.useState(false)
  const { user } = useLogin()
  const anonymousComment = comment.anonymousComment
  const isChild = tree && comment.parentId != null

  return (
    <ListItem
      divider
      sx={{
        padding: '16px 24px 24px 24px',
        borderLeft: isChild ? '16px solid #edf0f3' : '0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: 'auto'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '8px'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {commentIcon && (
            <Box
              sx={{ marginRight: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              {commentIcon}
            </Box>
          )}
          <Typography sx={{ fontSize: '14px' }}>{`${formatDistanceToNow(
            comment.notices && comment.notices.length > 0
              ? comment.notices[0].createdAt
              : comment.acceptedAt || comment.rejectedAt || comment.createdAt
          )} ago`}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {actions.filter(notNull).map(({ onClick, sx, text, variant, startIcon }) => (
            <Button
              startIcon={startIcon}
              key={text}
              onClick={() => onClick(comment)}
              sx={{ ...sx, marginLeft: 1 }}
              variant={variant}
              size="small"
            >
              {text}
            </Button>
          ))}
        </Box>
      </Box>
      {comment.notices && comment.notices.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            backgroundColor: 'info.main',
            color: 'info.contrastText',
            borderRadius: '4px',
            padding: '8px 16px',
            maxHeight: '60px',
            margin: '8px 0'
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '14px' }}>
            {`Reasons for flagging (${comment.notices.length}):`}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginLeft: 0.5,
              flex: 1,
              width: '1px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '14px'
            }}
          >
            {comment.notices.map(n => n.content).join(', ')}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Typography sx={{ fontSize: '16px', fontWeight: 500, marginRight: 1 }}>
          {comment.author}
        </Typography>
        <ModerationInfo comment={comment} />
        {!anonymousComment &&
          isModerator(user) &&
          (comment.createdByBannedUser ? (
            <Typography variant="body2">This user is banned</Typography>
          ) : (
            <Button
              onClick={() => {
                setShowBlockUser(true)
              }}
              sx={{
                fontSize: '14px',
                color: '#000',
                textDecoration: 'underline',
                fontWeight: '400',
                textTransform: 'none',
                lineHeight: '1em'
              }}
              size="small"
              variant="text"
            >
              Ban user
            </Button>
          ))}
      </Box>
      {comment.quote && <Quote author={comment.quote.author} content={comment.quote.content} />}
      <Typography data-testid="comment-list-item-content" sx={{ whiteSpace: 'pre-wrap' }}>
        {comment.content}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 1,
          marginBottom: -2,
          gap: 2,
          alignItems: 'flex-start'
        }}
      >
        <AiModeration comment={comment} automoderationLimits={automoderationLimits} />
        {comment.moderatedBy === automaticModerator && (
          <Chip
            onClick={() => onFilter(automaticModerator)}
            label={comment.acceptedAt === null ? 'Auto-rejected' : 'Auto-accepted'}
            variant="outlined"
          />
        )}
      </Box>
      <BlockModal
        key={comment.authenticatedAuthor}
        authorId={comment.authenticatedAuthor}
        author={comment.author}
        open={showBlockUser}
        handleClose={() => setShowBlockUser(false)}
        onSuccess={() => setShowBlockUser(false)}
      />
    </ListItem>
  )
}
