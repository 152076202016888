import React, { FC, useState } from 'react'
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { RemoveUserBlocks } from '../../hooks/userBlocks'
import { deleteBlock } from '../../api/comments-api'
import { useEndpoint } from '../../hooks/endpoint'
import { UserBlock } from '../../models/user'
import { BannedUserListItem } from './BannedUserListItem'

type BannedUserTableProps = {
  userBlocks: UserBlock[]
  removeUserBlocks: RemoveUserBlocks
}

export const BannedUserTable: FC<BannedUserTableProps> = ({ userBlocks, removeUserBlocks }) => {
  const [selectedItems, setSelectedItems] = useState<UserBlock[]>([])
  const multipleSelected = selectedItems.length > 0
  const deleteUserBlock = useEndpoint(deleteBlock)
  const userBlocksListItems = userBlocks.map(userBlock => {
    const userId = userBlock.user.id
    const isSelected = selectedItems.includes(userBlock)
    const toggleSelected = (userBlock: UserBlock) =>
      isSelected
        ? setSelectedItems(selectedItems.filter(x => userBlock !== x))
        : setSelectedItems([...selectedItems, userBlock])
    return (
      <BannedUserListItem
        isSelected={isSelected}
        toggleSelected={() => toggleSelected(userBlock)}
        key={userBlock.user.id}
        userBlock={userBlock}
        removeUserBlock={() =>
          deleteUserBlock({ userId }).then(() => {
            removeUserBlocks([userId])
            setSelectedItems(selectedItems.filter(item => item !== userBlock))
          })
        }
      />
    )
  })
  const removeSelected = () => {
    Promise.all(
      selectedItems.map(userBlock => deleteUserBlock({ userId: userBlock.user.id }))
    ).then(() => {
      removeUserBlocks(selectedItems.map(userBlock => userBlock.user.id))
      setSelectedItems([])
    })
  }

  const multipleSelectedText = () => {
    if (selectedItems.length === 1) {
      return '1 item selected'
    } else {
      return selectedItems.length + ' items selected'
    }
  }

  return (
    <TableContainer sx={{ mt: 0 }} component={Paper}>
      <Table aria-label="banned-users-table">
        <TableHead style={{ backgroundColor: '#E7E0EC' }}>
          <TableRow>
            <TableCell padding="checkbox" sx={{ paddingLeft: '15px' }}>
              <Checkbox
                checked={multipleSelected}
                onChange={event => setSelectedItems(event.target.checked ? userBlocks : [])}
              />
            </TableCell>
            <TableCell>{multipleSelected ? multipleSelectedText() : 'nick'}</TableCell>
            <TableCell>
              <span style={{ visibility: !multipleSelected ? 'visible' : 'hidden' }}>
                Reason for banning
              </span>
            </TableCell>
            <TableCell>
              <span style={{ visibility: !multipleSelected ? 'visible' : 'hidden' }}>
                Time banned
              </span>
            </TableCell>
            <TableCell>
              <span style={{ visibility: !multipleSelected ? 'visible' : 'hidden' }}>
                Moderator
              </span>
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              <Button
                onClick={removeSelected}
                sx={{
                  visibility: !multipleSelected ? 'hidden' : 'visible',
                  ml: 2,
                  mr: 1,
                  whiteSpace: 'noWrap'
                }}
                variant="contained"
                size="small"
              >
                Unban selected
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{userBlocksListItems}</TableBody>
      </Table>
    </TableContainer>
  )
}
