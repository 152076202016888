import React, { FC, useEffect, useState } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import type { AdminUser } from '../../models/user'
import { AdminUserListItem } from './AdminUserListItem'

type AdminUserTableProps = {
  users: AdminUser[]
  selectUser: (user: AdminUser) => void
}

const rowsPerPage = 20

export const AdminUserTable: FC<AdminUserTableProps> = ({ users, selectUser }) => {
  const [page, setPage] = useState(0)

  useEffect(() => {
    setPage(0)
  }, [users])

  return (
    <TableContainer sx={{ mt: 2 }} component={Paper}>
      <TablePagination
        component="div"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        count={users.length}
        page={page}
        onPageChange={(e, page) => setPage(page)}
      />
      <Table>
        <TableHead style={{ backgroundColor: '#E7E0EC' }}>
          <TableRow>
            <TableCell sx={{ width: { sm: 250 }, paddingLeft: '24px' }}>Name</TableCell>
            <TableCell sx={{ width: { sm: 250 } }}>Email</TableCell>
            <TableCell>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
            <AdminUserListItem key={user.id} user={user} onClick={() => selectUser(user)} />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        count={users.length}
        page={page}
        onPageChange={(e, page) => setPage(page)}
      />
    </TableContainer>
  )
}
