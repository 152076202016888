import type { RoleNameOrAll } from '../hooks/user'
import type { ErrorUser, UserData } from '../providers/LoginProvider'
import { AdminUser, AdminUserRoles } from '../models/user'
import { notNull } from './util'

export const isCommentator = (user: UserData) => user.loggedIn && user.isCommentator
export const isModerator = (user: UserData) => user.loggedIn && user.isModerator
export const isOperator = (user: UserData) => user.loggedIn && user.isOperator
export const isLoading = (user: UserData) => user.type === 'loading'
export const isError = (user: UserData): user is ErrorUser => user.type === 'error'

export const getRoles = (user?: AdminUser): AdminUserRoles => ({
  isCommentator: user?.isCommentator ?? false,
  isModerator: user?.isModerator ?? false,
  isOperator: user?.isOperator ?? false
})

export const formatRoles = (user: AdminUser): string =>
  [
    user.isOperator ? 'Operator' : null,
    user.isModerator ? 'Moderator' : null,
    user.isCommentator ? 'Commentator' : null
  ]
    .filter(notNull)
    .join(', ')

const userMatches = (search: string, role: RoleNameOrAll) => (user: AdminUser) => {
  if (role === 'isCommentator' && !user.isCommentator) {
    return false
  } else if (role === 'isModerator' && !user.isModerator) {
    return false
  } else if (role === 'isOperator' && !user.isOperator) {
    return false
  }
  return user.nick.toLowerCase().includes(search) || user.externalId.includes(search)
}

export const filterUsers = (
  users: AdminUser[],
  search: string,
  role: RoleNameOrAll
): AdminUser[] => {
  if (search === '' && role === 'all') {
    return users
  }
  return users.filter(userMatches(search.toLowerCase(), role))
}

export const compareUsers = (u1: AdminUser, u2: AdminUser): number => {
  const nick1 = u1.nick.toLowerCase()
  const nick2 = u2.nick.toLowerCase()
  if (nick1 < nick2) {
    return -1
  } else if (nick1 > nick2) {
    return 1
  }
  return 0
}
