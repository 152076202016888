import React, { FC, useEffect, useState } from 'react'
import { TextField, Button, Typography } from '@mui/material'
import { Modal } from '../Modal'
import { Comment } from '../../models/comment'
import { Quote } from '../Comment/Quote'
import { useLogin } from '../../providers/LoginProvider'
import { PostReplyParams } from '../../api/comments-api'

type ReplyModalProps = {
  open: boolean
  topicId?: string
  comment?: Comment
  handleReply: (params: PostReplyParams) => Promise<void>
  handleClose: () => void
}

export const ReplyModal: FC<ReplyModalProps> = ({
  open,
  topicId,
  comment,
  handleReply,
  handleClose
}) => {
  const [value, setValue] = useState('')
  const { user } = useLogin()

  useEffect(() => {
    setValue('')
  }, [open])

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={comment === undefined ? 'Write a comment' : 'Reply to comment'}
      actions={
        <Button
          onClick={async () => {
            if (topicId !== undefined) {
              await handleReply({ topicId, parentId: comment?.id, content: value })
              handleClose()
            }
          }}
          sx={{ ml: 2 }}
          variant="contained"
          size="small"
          disabled={topicId === undefined || value === ''}
        >
          Send
        </Button>
      }
    >
      {comment && <Quote author={comment.author} content={comment.content} />}
      {user.loggedIn && (
        <Typography sx={{ mb: 2 }}>
          Your comment is shown with screen name{' '}
          {
            <Typography component="span" sx={{ fontWeight: 500 }}>
              {`${user.firstName} ${user.lastName}`}
            </Typography>
          }
          .
        </Typography>
      )}
      <TextField
        value={value}
        onChange={e => setValue(e.target.value)}
        label="Your reply"
        multiline
        fullWidth
        minRows={4}
      />
    </Modal>
  )
}
