import React, { FC } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const AiModeratorLoveEyesIcon: FC<SvgIconProps> = props => (
  <SvgIcon sx={{ width: '16px', height: '16px', fill: 'none' }} viewBox="0 0 16 16" {...props}>
    <g id="robot-love-outline">
      <path
        id="Vector"
        d="M14.6667 8.33331H14.0001C14.0001 5.75331 11.9134 3.66665 9.33341 3.66665H8.66675V2.81998C9.06675 2.59331 9.33341 2.15998 9.33341 1.66665C9.33341 0.933313 8.74008 0.333313 8.00008 0.333313C7.26008 0.333313 6.66675 0.933313 6.66675 1.66665C6.66675 2.15998 6.93341 2.59331 7.33341 2.81998V3.66665H6.66675C4.08675 3.66665 2.00008 5.75331 2.00008 8.33331H1.33341C0.966748 8.33331 0.666748 8.63331 0.666748 8.99998V11C0.666748 11.3666 0.966748 11.6666 1.33341 11.6666H2.00008V12.3333C2.00008 13.0733 2.60008 13.6666 3.33341 13.6666H12.6667C13.4067 13.6666 14.0001 13.0733 14.0001 12.3333V11.6666H14.6667C15.0334 11.6666 15.3334 11.3666 15.3334 11V8.99998C15.3334 8.63331 15.0334 8.33331 14.6667 8.33331ZM14.0001 10.3333H12.6667V12.3333H3.33341V10.3333H2.00008V9.66665H3.33341V8.33331C3.33341 6.49331 4.82675 4.99998 6.66675 4.99998H9.33341C11.1734 4.99998 12.6667 6.49331 12.6667 8.33331V9.66665H14.0001V10.3333ZM7.13341 8.33331C7.40008 8.59331 7.40008 9.03331 7.13341 9.29998L6.64675 9.78665L5.66675 10.7666L4.20008 9.29998C3.93341 9.03331 3.93341 8.59331 4.20008 8.33331C4.47341 8.05331 4.90675 8.05331 5.18008 8.33331L5.66675 8.81331L6.15341 8.33331C6.42675 8.05331 6.86008 8.05331 7.13341 8.33331ZM11.8001 8.33331C12.0667 8.59331 12.0667 9.03331 11.8001 9.29998L11.3134 9.78665L10.3334 10.7666L8.86675 9.29998C8.60008 9.03331 8.60008 8.59331 8.86675 8.33331C9.14008 8.05331 9.57341 8.05331 9.84675 8.33331L10.3334 8.81331L10.8201 8.33331C11.0934 8.05331 11.5267 8.05331 11.8001 8.33331Z"
        fill="black"
      />
    </g>
  </SvgIcon>
)

export const AiModeratorCircleEyesIcon: FC<SvgIconProps> = props => (
  <SvgIcon sx={{ width: '16px', height: '16px', fill: 'none' }} viewBox="0 0 16 16" {...props}>
    <g id="robot-outline">
      <path
        id="Vector"
        d="M11.6667 9.33331C11.6667 10.0733 11.0734 10.6666 10.3334 10.6666C9.59341 10.6666 9.00008 10.0733 9.00008 9.33331C9.00008 8.59331 9.60008 7.99998 10.3334 7.99998C11.0667 7.99998 11.6667 8.59998 11.6667 9.33331ZM5.66675 7.99998C4.93341 7.99998 4.33341 8.59998 4.33341 9.33331C4.33341 10.0666 4.93341 10.6666 5.66675 10.6666C6.40008 10.6666 7.00008 10.0733 7.00008 9.33331C7.00008 8.59331 6.40675 7.99998 5.66675 7.99998ZM15.3334 8.99998V11C15.3334 11.3666 15.0334 11.6666 14.6667 11.6666H14.0001V12.3333C14.0001 13.0733 13.4067 13.6666 12.6667 13.6666H3.33341C2.60008 13.6666 2.00008 13.0733 2.00008 12.3333V11.6666H1.33341C0.966748 11.6666 0.666748 11.3666 0.666748 11V8.99998C0.666748 8.63331 0.966748 8.33331 1.33341 8.33331H2.00008C2.00008 5.75331 4.08675 3.66665 6.66675 3.66665H7.33341V2.81998C6.93341 2.59331 6.66675 2.15998 6.66675 1.66665C6.66675 0.933313 7.26675 0.333313 8.00008 0.333313C8.73341 0.333313 9.33341 0.933313 9.33341 1.66665C9.33341 2.15998 9.06675 2.59331 8.66675 2.81998V3.66665H9.33341C11.9134 3.66665 14.0001 5.75331 14.0001 8.33331H14.6667C15.0334 8.33331 15.3334 8.63331 15.3334 8.99998ZM14.0001 9.66665H12.6667V8.33331C12.6667 6.49331 11.1734 4.99998 9.33341 4.99998H6.66675C4.82675 4.99998 3.33341 6.49331 3.33341 8.33331V9.66665H2.00008V10.3333H3.33341V12.3333H12.6667V10.3333H14.0001V9.66665Z"
        fill="black"
      />
    </g>
  </SvgIcon>
)

export const AiModeratorCrossEyesIcon: FC<SvgIconProps> = props => (
  <SvgIcon sx={{ width: '16px', height: '16px', fill: 'none' }} viewBox="0 0 16 16" {...props}>
    <g id="robot-dead-outline">
      <path
        id="Vector"
        d="M7.08008 8.62665L6.37341 9.33331L7.08008 10.04L6.37341 10.7466L5.66675 10.04L4.96008 10.7466L4.25341 10.04L4.96008 9.33331L4.25341 8.62665L4.96008 7.91998L5.66675 8.62665L6.37341 7.91998L7.08008 8.62665ZM11.0401 7.91998L10.3334 8.62665L9.62675 7.91998L8.92008 8.62665L9.62675 9.33331L8.92008 10.04L9.62675 10.7466L10.3334 10.04L11.0401 10.7466L11.7467 10.04L11.0401 9.33331L11.7467 8.62665L11.0401 7.91998ZM15.3334 8.99998V11C15.3334 11.3666 15.0334 11.6666 14.6667 11.6666H14.0001V12.3333C14.0001 13.0733 13.4067 13.6666 12.6667 13.6666H3.33341C2.60008 13.6666 2.00008 13.0733 2.00008 12.3333V11.6666H1.33341C0.966748 11.6666 0.666748 11.3666 0.666748 11V8.99998C0.666748 8.63331 0.966748 8.33331 1.33341 8.33331H2.00008C2.00008 5.75331 4.08675 3.66665 6.66675 3.66665H7.33342V2.81998C6.93341 2.59331 6.66675 2.15998 6.66675 1.66665C6.66675 0.933313 7.26675 0.333313 8.00008 0.333313C8.73341 0.333313 9.33342 0.933313 9.33342 1.66665C9.33342 2.15998 9.06675 2.59331 8.66675 2.81998V3.66665H9.33342C11.9134 3.66665 14.0001 5.75331 14.0001 8.33331H14.6667C15.0334 8.33331 15.3334 8.63331 15.3334 8.99998ZM14.0001 9.66665H12.6667V8.33331C12.6667 6.49331 11.1734 4.99998 9.33342 4.99998H6.66675C4.82675 4.99998 3.33341 6.49331 3.33341 8.33331V9.66665H2.00008V10.3333H3.33341V12.3333H12.6667V10.3333H14.0001V9.66665Z"
        fill="black"
      />
    </g>
  </SvgIcon>
)
