import { SentimentVerySatisfied, ThumbUpOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
import React, { FC, ReactElement } from 'react'
import { FilterTab } from './CommentFilterTabs'

type NoCommentsProps = {
  tab: FilterTab
}

const content: Record<FilterTab, { icon: ReactElement | null; text: string }> = {
  ALL: {
    icon: null,
    text: 'No comments yet'
  },
  ACCEPTED: {
    icon: null,
    text: 'No accepted comments yet.'
  },
  REJECTED: {
    icon: <ThumbUpOutlined sx={{ marginRight: 1 }} />,
    text: 'No rejected comments.'
  },
  UNMODERATED: {
    icon: <ThumbUpOutlined sx={{ marginRight: 1 }} />,
    text: 'Good job! No unmoderated comments in this topic.'
  },
  FLAGGED: {
    icon: <SentimentVerySatisfied sx={{ marginRight: 1 }} />,
    text: 'No flagged comments. Good job!'
  }
}

export const NoComments: FC<NoCommentsProps> = ({ tab }) => {
  return (
    <Typography
      sx={{
        padding: '16px 24px',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      {content[tab].icon}
      {content[tab].text}
    </Typography>
  )
}
