import React, { FC, ReactNode } from 'react'
import { createContext } from '../utils/context'
import {
  TopicsAndComments,
  useTopicsAndComments
} from '../hooks/topicsAndComments/topicsAndComments'
import { AutomoderationLimits } from '../models/automoderationLimits'
import { useAutomoderationLimits } from '../hooks/automoderationLimits'

export type ModerationState = {
  topicsAndComments: TopicsAndComments
  automoderationLimits: AutomoderationLimits | null
}

const [useModerationState, Provider] = createContext<ModerationState>('ModerationStateContext')

export { useModerationState }

export const ModerationStateProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const topicsAndComments = useTopicsAndComments()
  const automoderationLimits = useAutomoderationLimits()

  return <Provider value={{ topicsAndComments, automoderationLimits }}>{children}</Provider>
}
