import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Tag } from '../../models/tag'
import { Toggle } from './Toggle'

type TagsProps = {
  allTags: Tag[]
  userTags: Set<string>
  setUserTags: React.Dispatch<React.SetStateAction<Set<string>>>
}

export const Tags: FC<TagsProps> = ({ allTags, userTags, setUserTags }) => {
  const toggleTag = (tagId: string, selected: boolean) => {
    if (selected) {
      setUserTags(oldTags => new Set(oldTags).add(tagId))
    } else {
      setUserTags(oldTags => {
        const newTags = new Set(oldTags)
        newTags.delete(tagId)
        return newTags
      })
    }
  }
  return (
    <Box>
      <Typography variant="h6" sx={{ fontSize: '16px', marginBottom: '8px' }}>
        Moderation rights
      </Typography>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', rowGap: 1, columnGap: 2 }}
      >
        {allTags.map(tag => {
          const selected = userTags.has(tag.id)
          return (
            <Toggle
              key={`${tag.id}`}
              selected={selected}
              label={tag.name}
              onClick={() => toggleTag(tag.id, !selected)}
            />
          )
        })}
      </Box>
    </Box>
  )
}
