import { useEffect, useState } from 'react'
import { getTags } from '../api/comments-api'
import { Tag } from '../models/tag'
import { useEndpoint } from './endpoint'

export const useTags = (): Tag[] => {
  const [tags, setTags] = useState<Tag[]>([])
  const fetchTags = useEndpoint(getTags)

  useEffect(() => {
    fetchTags().then(setTags)
  }, [fetchTags])

  return tags
}
