import React, { FC, ReactNode } from 'react'
import { Box, Drawer, List, ListItemButton } from '@mui/material'
import { listItemButtonClasses } from '@mui/material/ListItemButton'
import { lighten, useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { Message, People } from '@mui/icons-material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { Route, routes } from '../../constants/routes'
import { useLogin } from '../../providers/LoginProvider'
import { isModerator, isOperator } from '../../utils/user'
import { CommentsAdminLogo } from './Logo'
import { GoogleAvatar } from './GoogleAvatar'

type SideDrawerProps = {
  isOpen: boolean
  currentRoute?: Route
}

type RouteItem = {
  name: string
  id: Route
  icon: ReactNode
}

const FeedbackButton = () => (
  <ListItemButton
    color="secondary"
    sx={{
      p: 2,
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: 14,
      color: 'primary.contrastText',
      flex: '0 0 auto',
      mb: 1
    }}
    href="https://docs.google.com/forms/d/e/1FAIpQLSddNJkYBxndmXfgenBy11_oEtRuML-q2FpzMRbFscojUzzV3A/viewform"
    target="_blank"
  >
    <Message />
    <div>Feedback</div>
  </ListItemButton>
)

export const SideDrawer: FC<SideDrawerProps> = ({ isOpen, currentRoute }) => {
  const { user, logout } = useLogin()
  const theme = useTheme()
  const navigate = useNavigate()

  const commonRoutes: RouteItem[] = [{ name: 'Moderation', id: 'moderation', icon: <Message /> }]

  const moderatorRoutes: RouteItem[] = isModerator(user)
    ? [{ name: 'Banned Users', id: 'bannedUsers', icon: <People /> }]
    : []

  const operatorRoutes: RouteItem[] = isOperator(user)
    ? [{ name: 'Moderator management', id: 'adminUsers', icon: <AdminPanelSettingsIcon /> }]
    : []

  const allRoutes = [...commonRoutes, ...moderatorRoutes, ...operatorRoutes]

  const drawerContent = (
    <>
      <CommentsAdminLogo />
      <List sx={{ marginTop: { xs: 9, sm: 9, md: 2 } }}>
        <>
          {allRoutes.map(route => (
            <ListItemButton
              selected={route.id === currentRoute}
              color="secondary"
              sx={{
                p: 2,
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: 14,
                color: 'primary.contrastText',
                [`&.${listItemButtonClasses.selected}`]: {
                  backgroundColor: theme => lighten(theme.palette.primary.dark, 0.1)
                }
              }}
              key={route.name}
              onClick={() => navigate(routes[route.id])}
            >
              {route.icon}
              <div>{route.name}</div>
            </ListItemButton>
          ))}
        </>
      </List>
    </>
  )

  return (
    <>
      <Drawer
        open={isOpen}
        variant="temporary"
        sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, flexShrink: 0, width: 105 }}
        PaperProps={{
          sx: {
            width: 105,
            boxSizing: 'border-box',
            background: theme.palette.primary.dark
          }
        }}
      >
        {drawerContent}
        <Box sx={{ flex: '1 0 0' }} />
        <FeedbackButton />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, flexShrink: 0, width: 105 }}
        PaperProps={{
          sx: {
            width: 105,
            boxSizing: 'border-box',
            background: theme.palette.primary.dark,
            alignItems: 'stretch'
          }
        }}
      >
        {drawerContent}
        <Box sx={{ flex: '1 0 0' }} />
        <FeedbackButton />
        <GoogleAvatar user={user} logout={logout} sx={{ mb: 2, alignSelf: 'center' }} />
      </Drawer>
    </>
  )
}
