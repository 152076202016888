import React, { FC, ReactNode } from 'react'
import { Paper, Theme, SxProps } from '@mui/material'

type ListContainerProps = {
  sx?: SxProps<Theme>
  children: ReactNode
}

export const ListContainer: FC<ListContainerProps> = ({ sx, children }) => (
  <Paper
    sx={{
      padding: 0,
      flex: 1,
      border: '1px solid #79747E',
      borderBottom: 'none',
      borderRadius: '16px 16px 0 0',
      boxShadow: 'none',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      ...sx
    }}
  >
    {children}
  </Paper>
)
