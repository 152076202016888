import type { ApiOptions } from '../providers/ApiOptionsProvider'
import NotFoundError from '../errors/NotFoundError'

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export const fetchJson = async <T>(
  { appId, appKey, commentsApiUrl }: ApiOptions,
  token: string,
  endpoint: string,
  options: RequestInit,
  extraParameters = ''
): Promise<T> => {
  const url = `${commentsApiUrl}/${endpoint}?app_id=${appId}&app_key=${appKey}&${extraParameters}`

  const headers = {
    Authorization: `Bearer ${token}`,
    ...options.headers
  }

  const request = {
    ...options,
    headers
  }

  const response = await fetch(url, request)

  if (response.status === 404) {
    throw new NotFoundError(`${endpoint} not found`)
  } else if (response.status >= 400) {
    let message, cause
    try {
      const body = await response.json()
      message = body.notifications[0].message
    } catch (e) {
      message = '[No error message]'
      cause = e
    }
    throw new Error(`Call to ${endpoint} failed with ${response.status}: ${message}`, { cause })
  }

  return response.json()
}

export const getJson = <T>(
  apiOptions: ApiOptions,
  token: string,
  endpoint: string,
  extraParams = ''
): Promise<T> => fetchJson<T>(apiOptions, token, endpoint, { method: 'GET' }, extraParams)

export const fetchJsonWithBody = <T>(
  apiOptions: ApiOptions,
  token: string,
  endpoint: string,
  body: unknown,
  method: Method = 'POST',
  extraParameters = ''
): Promise<T> => {
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: body === undefined ? undefined : JSON.stringify(body)
  }
  return fetchJson(apiOptions, token, endpoint, options, extraParameters)
}
