import { FilterTab } from '../../components/Comment/CommentFilterTabs'
import { TopicFilter } from '../../components/Topic/TopicStateFilter'
import { Comment } from '../../models/comment'
import { Notice } from '../../models/notice'
import { Topic } from '../../models/topic'
import { Action } from './actions'

export type TopicsAndCommentsState = {
  topics: Topic[]
  singleTopic: Topic | undefined
  selectedTopic: string | undefined
  topicFilter: TopicFilter
  comments: Comment[]
  notices: Notice[]
  tab: FilterTab
  loading: boolean
}

export const hasDifference = <T>(coll1: T[], coll2: T[]) =>
  JSON.stringify(coll1) !== JSON.stringify(coll2)

export const replace = (comments: Comment[], replacement: Comment) => [
  ...comments.filter(c => c.id !== replacement.id),
  replacement
]

export const noMoreUnmoderatedComments = (comments: Comment[]): boolean =>
  comments.every(c => c.acceptedAt !== null || c.rejectedAt !== null)

const selectNextTopic = (state: TopicsAndCommentsState): TopicsAndCommentsState => {
  if (state.notices.length === 0 && noMoreUnmoderatedComments(state.comments)) {
    const nextTopic = state.topics.find(
      topic =>
        topic.id !== state.selectedTopic &&
        (topic.unmoderatedCommentsCount > 0 || topic.unprocessedNoticesCount > 0)
    )
    if (nextTopic) {
      return {
        ...state,
        comments: [],
        notices: [],
        loading: true,
        selectedTopic: nextTopic.id
      }
    }
  }
  return state
}

export const reducer = (state: TopicsAndCommentsState, action: Action): TopicsAndCommentsState => {
  switch (action.type) {
    case 'SET_TOPICS':
      return {
        ...state,
        selectedTopic: state.selectedTopic ?? action.topics[0]?.id,
        topics: action.topics
      }
    case 'SELECT_TOPIC':
      return {
        ...state,
        singleTopic: undefined,
        comments: [],
        notices: [],
        selectedTopic: action.id,
        loading: true
      }
    case 'UPDATE_TOPIC':
      return {
        ...state,
        topics: state.topics.map(t => (t.id === action.topic.id ? action.topic : t))
      }
    case 'SET_IS_AUTOMODERATED': {
      const { id, isAutomoderated } = action
      return {
        ...state,
        topics: state.topics.map(t => (t.id === id ? { ...t, isAutomoderated } : t))
      }
    }
    case 'SET_SINGLE_TOPIC':
      return {
        ...state,
        singleTopic: action.topic,
        ...(action.topic.id !== state.selectedTopic && {
          comments: [],
          notices: [],
          selectedTopic: action.topic.id,
          loading: true
        })
      }
    case 'SET_TOPIC_FILTER':
      return {
        ...state,
        topicFilter: action.filter
      }
    case 'SET_COMMENTS': {
      const { comments, notices, tab } = state
      return {
        ...state,
        comments: hasDifference(comments, action.comments) ? action.comments : comments,
        notices: hasDifference(notices, action.notices) ? action.notices : notices,
        tab: action.tab || tab,
        loading: false
      }
    }
    case 'CLOSE_NOTICES': {
      const ids = action.notices.map(n => n.id)
      const notices = state.notices.filter(n => !ids.includes(n.id))
      return selectNextTopic({
        ...state,
        notices
      })
    }
    case 'MODERATE_COMMENT': {
      const { comment } = action
      const comments = replace(state.comments, comment)
      const notices =
        action.action === 'REJECT'
          ? state.notices.filter(n => n.commentId !== comment.id)
          : state.notices
      return selectNextTopic({
        ...state,
        comments,
        notices
      })
    }
    case 'NEW_COMMENT':
      return {
        ...state,
        comments: [...state.comments, action.comment]
      }
    case 'SET_TAB':
      return {
        ...state,
        tab: action.tab
      }
  }
}
