import React, { FC } from 'react'
import { Box, TableCell, TableRow } from '@mui/material'
import type { AdminUser } from '../../models/user'
import { UserAvatar } from '../UserAvatar/UserAvatar'
import { formatRoles } from '../../utils/user'

type UserListItemProps = {
  user: AdminUser
  onClick: () => void
}

export const AdminUserListItem: FC<UserListItemProps> = ({ user, onClick }) => (
  <TableRow hover sx={{ cursor: 'pointer' }} onClick={onClick}>
    <TableCell sx={{ fontWeight: 500, paddingLeft: '24px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <UserAvatar sx={{ mr: 2 }} alt={user.nick} photoUrl={user.photoUrl || undefined} />
        {user.nick}
      </Box>
    </TableCell>
    <TableCell>{user.externalId}</TableCell>
    <TableCell>{formatRoles(user)}</TableCell>
  </TableRow>
)
