import { AccountCircle } from '@mui/icons-material'
import { Avatar, SxProps, Theme } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { FC, MouseEvent } from 'react'

type UserAvatarProps = {
  alt?: string
  photoUrl?: string
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  sx?: SxProps<Theme>
}

export const UserAvatar: FC<UserAvatarProps> = ({ alt, photoUrl, onClick, sx }) => {
  const theme = useTheme()
  return (
    <Avatar
      alt={alt}
      src={photoUrl}
      sx={{
        color: 'primary.main',
        backgroundColor: 'primary.contrastText',
        ...sx
      }}
      onClick={onClick}
    >
      {photoUrl === undefined && (
        <AccountCircle fontSize="large" htmlColor={theme.palette.primary.main} />
      )}
    </Avatar>
  )
}
