import { FilterTab } from '../../components/Comment/CommentFilterTabs'
import { TopicFilter } from '../../components/Topic/TopicStateFilter'
import { Comment } from '../../models/comment'
import { Notice } from '../../models/notice'
import { Topic } from '../../models/topic'

export type SetTopicsAction = {
  type: 'SET_TOPICS'
  topics: Topic[]
}

export const setTopicsAction = (topics: Topic[]): SetTopicsAction => ({
  type: 'SET_TOPICS',
  topics
})

export type SelectTopicAction = {
  type: 'SELECT_TOPIC'
  id: string | undefined
}

export const selectTopicAction = (id: string | undefined): SelectTopicAction => ({
  type: 'SELECT_TOPIC',
  id
})

export type UpdateTopicAction = {
  type: 'UPDATE_TOPIC'
  topic: Topic
}

export const updateTopicAction = (topic: Topic): UpdateTopicAction => ({
  type: 'UPDATE_TOPIC',
  topic
})

export type SetIsAutomoderatedAction = {
  type: 'SET_IS_AUTOMODERATED'
  id: string
  isAutomoderated: boolean
}

export const setIsAutomoderatedAction = (
  id: string,
  isAutomoderated: boolean
): SetIsAutomoderatedAction => ({
  type: 'SET_IS_AUTOMODERATED',
  id,
  isAutomoderated
})

export type SetSingleTopicAction = {
  type: 'SET_SINGLE_TOPIC'
  topic: Topic
}

export const setSingleTopicAction = (topic: Topic): SetSingleTopicAction => ({
  type: 'SET_SINGLE_TOPIC',
  topic
})

export type SetTopicFilterAction = {
  type: 'SET_TOPIC_FILTER'
  filter: TopicFilter
}

export const setTopicFilterAction = (filter: TopicFilter): SetTopicFilterAction => ({
  type: 'SET_TOPIC_FILTER',
  filter
})

export type SetCommentsAction = {
  type: 'SET_COMMENTS'
  comments: Comment[]
  notices: Notice[]
  tab: FilterTab | undefined
}

export const setCommentsAction = (
  comments: Comment[],
  notices: Notice[],
  tab: FilterTab | undefined
): SetCommentsAction => ({
  type: 'SET_COMMENTS',
  comments,
  notices,
  tab
})

export type CloseNoticesAction = {
  type: 'CLOSE_NOTICES'
  notices: Notice[]
}

export const closeNoticesAction = (notices: Notice[]): CloseNoticesAction => ({
  type: 'CLOSE_NOTICES',
  notices
})

export type ModerateCommentAction = {
  type: 'MODERATE_COMMENT'
  comment: Comment
  action: 'ACCEPT' | 'REJECT'
}

export const moderateCommentAction = (
  comment: Comment,
  action: 'ACCEPT' | 'REJECT'
): ModerateCommentAction => ({
  type: 'MODERATE_COMMENT',
  comment,
  action
})

export type NewCommentAction = {
  type: 'NEW_COMMENT'
  comment: Comment
}

export const newCommentAction = (comment: Comment): NewCommentAction => ({
  type: 'NEW_COMMENT',
  comment
})

export type SetTabAction = {
  type: 'SET_TAB'
  tab: FilterTab
}

export const setTabAction = (tab: FilterTab): SetTabAction => ({
  type: 'SET_TAB',
  tab
})

export type Action =
  | SetTopicsAction
  | SelectTopicAction
  | UpdateTopicAction
  | SetSingleTopicAction
  | SetTopicFilterAction
  | SetCommentsAction
  | CloseNoticesAction
  | ModerateCommentAction
  | NewCommentAction
  | SetTabAction
  | SetIsAutomoderatedAction

export type ActionType = Action['type']
