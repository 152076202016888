import { isFuture } from 'date-fns'
import { Topic } from '../models/topic'
import { sum } from './util'

export const countUnprocessedItems = (topics: Topic[]): number =>
  sum(topics.map(topic => topic.unmoderatedCommentsCount + topic.unprocessedNoticesCount))

export const isAreenaTopic = (topic: Topic) => topic.externalId.match(/^1-\d+$/) != null

export const isUpcomingTopic = (topic: Topic) => topic.openedAt != null && isFuture(topic.openedAt)
