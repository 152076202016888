import { useEffect, useState } from 'react'
import { getUserBlock } from '../api/comments-api'
import type { Block } from '../models/user'
import { useEndpoint } from './endpoint'

type UseBlock = {
  userId?: string
}

export const useBlock = ({ userId }: UseBlock): Block | undefined => {
  const [block, setBlock] = useState<Block | undefined>()
  const fetchUserBlock = useEndpoint(getUserBlock)

  useEffect(() => {
    if (userId) {
      fetchUserBlock({ userId }).then(userBlock => setBlock(userBlock.block || undefined))
    } else {
      setBlock(undefined)
    }
  }, [userId, fetchUserBlock])

  return block
}
