import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { Box, Divider, Fab, IconButton, ToggleButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Create, OpenInNew, Reply, Settings } from '@mui/icons-material'
import { Comment } from '../../models/comment'
import { CommentListItem, CommentListItemAction, RenderComment } from './CommentListItem'
import { Topic } from '../../models/topic'
import { formatTimestamp } from '../../utils/date-utils'
import { isCommentator, isModerator } from '../../utils/user'
import { ListContainer } from '../ListContainer/ListContainer'
import { ReplyModal } from '../ReplyModal/ReplyModal'
import { SearchFilter } from '../SearchFilter/SearchFilter'
import { CommentFilterTabs, FilterTab, COMMENT_FILTER_ICON, FILTER_TABS } from './CommentFilterTabs'
import { Notice } from '../../models/notice'
import { NoComments } from './NoComments'
import { useLogin, UserData } from '../../providers/LoginProvider'
import { groupBy } from '../../utils/util'
import { isAccepted, isRejected, isUnmoderated } from '../../utils/comment'
import { CommentActions } from '../../hooks/topicsAndComments/topicsAndComments'
import { isAreenaTopic } from '../../utils/topic'
import { palette } from '../../styles/colors'
import { AutomoderationLimits } from '../../models/automoderationLimits'

type CommentListProps = {
  topic?: Topic
  comments: Comment[]
  notices: Notice[]
  actions: CommentActions
  tab: FilterTab
  setTab: (tab: FilterTab) => void
  loading: boolean
  editTopic?: (topic: Topic) => void
  automoderationLimits: AutomoderationLimits | null
}

const filterBy = (tab: FilterTab, notices: Notice[]): ((comment: Comment) => boolean) => {
  switch (tab) {
    case 'ALL':
      return () => true
    case 'ACCEPTED':
      return isAccepted
    case 'UNMODERATED':
      return isUnmoderated
    case 'FLAGGED':
      return (comment: Comment) => Boolean(notices.find(n => n.commentId === comment.id))
    case 'REJECTED':
      return isRejected
  }
}
const getCommentIcon = (tab: FilterTab, comment: Comment): ReactElement | undefined => {
  const { acceptedAt, rejectedAt } = comment
  if (tab === 'FLAGGED') {
    return COMMENT_FILTER_ICON.FLAGGED
  } else if (acceptedAt === null && rejectedAt === null) {
    return COMMENT_FILTER_ICON.UNMODERATED
  } else if (acceptedAt !== null) {
    return COMMENT_FILTER_ICON.ACCEPTED
  } else if (rejectedAt !== null) {
    return COMMENT_FILTER_ICON.REJECTED
  }
  return undefined
}

type SortFn = (a: RenderComment, b: RenderComment) => number

const reverseSort =
  (sortFn: SortFn): SortFn =>
  (a, b) =>
    -sortFn(a, b)

export const makeCommentTree = (
  comments: RenderComment[],
  topLevelSort: SortFn,
  childrenSort: SortFn
): RenderComment[] => {
  const grouped = groupBy(comments, c => c.parentId)

  const topLevelComments = comments.filter(c => c.parentId == null).sort(topLevelSort)

  function getChildren(c: RenderComment): RenderComment[] {
    const children = Array.from(grouped[c.id] ?? []).sort(childrenSort)
    return [c, ...children.flatMap(getChildren)]
  }

  return topLevelComments.flatMap(getChildren)
}

const sortBy: Record<FilterTab, SortFn> = {
  ALL: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
  ACCEPTED: (a, b) => b.acceptedAt!.getTime() - a.acceptedAt!.getTime(),
  UNMODERATED: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
  FLAGGED: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
  REJECTED: (a, b) => b.rejectedAt!.getTime() - a.rejectedAt!.getTime()
}

const sortComments = (tab: FilterTab, comments: RenderComment[]): RenderComment[] => {
  if (tab === 'ACCEPTED') {
    return makeCommentTree(comments, sortBy.ACCEPTED, sortBy.ACCEPTED)
  }
  if (tab === 'ALL') {
    return makeCommentTree(comments, sortBy.ALL, reverseSort(sortBy.ALL))
  }
  return comments.sort(sortBy[tab])
}

const searchBy = (search: string) => {
  const regexp = new RegExp(search, 'i')
  return (comment: RenderComment) =>
    comment.content.match(regexp) ||
    comment.author.match(regexp) ||
    comment.moderatedBy?.match(regexp)
}

const getCommentActions = (
  actions: CommentActions,
  tab: FilterTab,
  comment: Comment,
  userIsModerator: boolean,
  handleReply?: (comment: Comment) => void
): (CommentListItemAction | null)[] => {
  const reject: CommentListItemAction = {
    onClick: actions.onRejectComment,
    text: tab === 'FLAGGED' ? 'Reject message' : 'Reject',
    sx: { color: 'error.main' },
    variant: 'text'
  }
  const reply: CommentListItemAction | null = handleReply
    ? {
        onClick: handleReply,
        text: 'Reply',
        variant: 'outlined',
        startIcon: <Reply />
      }
    : null
  const accept: CommentListItemAction = {
    onClick: actions.onAcceptComment,
    text: 'Accept',
    variant: 'contained'
  }
  const closeNotices: CommentListItemAction = {
    onClick: comment => comment.notices && actions.onCloseNotices(comment.notices),
    text: 'Ignore flagging',
    variant: 'contained'
  }
  switch (tab) {
    case 'ALL':
      return [
        userIsModerator && !isAccepted(comment) ? accept : null,
        userIsModerator && !isRejected(comment) ? reject : null,
        isAccepted(comment) ? reply : null
      ]
    case 'ACCEPTED':
      return [userIsModerator ? reject : null, reply]
    case 'UNMODERATED':
      return [reject, accept]
    case 'FLAGGED':
      return [reject, closeNotices]
    case 'REJECTED':
      return [{ ...accept, variant: 'outlined' }]
  }
}

const toRenderComment =
  (tab: FilterTab, comments: Comment[], notices: Notice[]) =>
  (comment: Comment): RenderComment => {
    return {
      ...comment,
      notices:
        tab === 'FLAGGED'
          ? notices.filter(n => n.commentId === comment.id).filter(n => !!n.content)
          : null,
      quote: comment.parentId ? comments.find(c => c.id === comment.parentId) || null : null
    }
  }

const canReply = (user: UserData, comment: Comment): boolean =>
  user.loggedIn && user.isCommentator && comment.acceptedAt !== null

const A = styled('a')({
  textDecoration: 'underline',
  cursor: 'pointer',
  color: palette.black.normal
})

export const CommentList: FC<CommentListProps> = ({
  topic,
  comments,
  notices,
  actions,
  tab,
  setTab,
  loading,
  editTopic,
  automoderationLimits
}) => {
  const { user } = useLogin()
  const userIsModerator = isModerator(user)
  const [isAutomoderationLoading, setIsAutomoderationLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [renderComments, setRenderComments] = useState<Record<FilterTab, RenderComment[]>>({
    ALL: [],
    ACCEPTED: [],
    UNMODERATED: [],
    FLAGGED: [],
    REJECTED: []
  })
  // null means that comment modal is open but user is writing a top level comment
  const [replyTo, setReplyTo] = useState<Comment | null | undefined>()

  useEffect(() => {
    const [all, flagged, unmoderated, accepted, rejected] = FILTER_TABS.map(tab =>
      sortComments(
        tab,
        comments.filter(filterBy(tab, notices)).map(toRenderComment(tab, comments, notices))
      )
    )
    setRenderComments({
      ALL: all,
      ACCEPTED: accepted,
      UNMODERATED: unmoderated,
      FLAGGED: flagged,
      REJECTED: rejected
    })
  }, [comments, notices])

  const searchedRenderComments = searchText
    ? renderComments[tab].filter(searchBy(searchText))
    : renderComments[tab]

  const firstCommentCreatedAt = comments.length ? comments[0].createdAt : undefined

  return (
    <>
      <ListContainer
        sx={{
          flex: 1.6,
          marginLeft: { xs: 0, sm: '24px' }
        }}
      >
        <Box sx={{ padding: 3, paddingBottom: 0 }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2 }}
          >
            <Box>
              <Typography variant="h4" sx={{ fontSize: '22px' }}>
                {topic && topic.url ? (
                  <>
                    <A href={topic.url} target="_blank" rel="noopener noreferrer">
                      {topic?.title}
                    </A>
                    <IconButton
                      aria-label="open article"
                      size="small"
                      href={topic.url}
                      target="_blank"
                      sx={{ color: palette.black.normal }}
                      rel="noopener noreferrer"
                    >
                      <OpenInNew fontSize="small" />
                    </IconButton>
                  </>
                ) : (
                  topic?.title
                )}
                {editTopic !== undefined && topic !== undefined && isAreenaTopic(topic) && (
                  <IconButton aria-label="edit" onClick={() => editTopic(topic)}>
                    <Settings />
                  </IconButton>
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
                alignSelf: 'flex-start',
                gap: 1
              }}
            >
              <Typography variant="body2">Auto Reject:</Typography>
              <ToggleButton
                value="isAutomoderated"
                selected={topic?.isAutomoderated}
                disabled={topic === undefined || isAutomoderationLoading}
                size="small"
                sx={{
                  lineHeight: 1,
                  textTransform: 'none',
                  '&.Mui-selected': {
                    bgcolor: 'success.main',
                    color: 'success.contrastText',
                    '&:hover': {
                      bgcolor: 'success.main'
                    }
                  }
                }}
                onChange={async () => {
                  if (topic !== undefined) {
                    setIsAutomoderationLoading(true)
                    await actions.onSetIsAutomoderated({
                      id: topic.id,
                      isAutomoderated: !topic.isAutomoderated
                    })
                    setIsAutomoderationLoading(false)
                  }
                }}
              >
                {topic?.isAutomoderated ? 'On' : 'Off'}
              </ToggleButton>
            </Box>
          </Box>
          {firstCommentCreatedAt && (
            <Typography sx={{ fontSize: '12px' }}>
              {`(First comment ${formatTimestamp(firstCommentCreatedAt, {
                weekday: undefined
              })})`}
            </Typography>
          )}
          <SearchFilter
            id="comments-filter"
            placeholder="Filter comments..."
            value={searchText}
            onChange={setSearchText}
            fullWidth
          />
          {userIsModerator && (
            <CommentFilterTabs
              value={tab}
              onChange={setTab}
              counts={
                FILTER_TABS.reduce(
                  (a, b) => ({ ...a, [b]: renderComments[b].length }),
                  {}
                ) as Record<FilterTab, number>
              }
              filtered={searchText ? searchedRenderComments.length : null}
            />
          )}
        </Box>
        <Divider />
        {!loading &&
          (searchedRenderComments.length === 0 ? (
            <NoComments tab={tab} />
          ) : (
            <Virtuoso
              totalCount={searchedRenderComments.length}
              itemContent={(index: number) => {
                const comment = searchedRenderComments[index]
                return (
                  <CommentListItem
                    key={comment.id}
                    comment={comment}
                    commentIcon={getCommentIcon(tab, comment)}
                    actions={getCommentActions(
                      actions,
                      tab,
                      comment,
                      userIsModerator,
                      canReply(user, comment) ? setReplyTo : undefined
                    )}
                    onFilter={setSearchText}
                    automoderationLimits={automoderationLimits}
                    tree={tab === 'ACCEPTED' || tab === 'ALL'}
                  />
                )
              }}
            />
          ))}
      </ListContainer>
      {isCommentator(user) && (
        <Fab
          color="primary"
          variant="extended"
          sx={{ position: 'fixed', right: 16, bottom: 16 }}
          onClick={() => setReplyTo(null)}
        >
          <Create sx={{ mr: 1 }} />
          Write comment
        </Fab>
      )}
      <ReplyModal
        open={replyTo !== undefined}
        topicId={topic?.id}
        comment={replyTo || undefined}
        handleClose={() => setReplyTo(undefined)}
        handleReply={actions.onReplyComment}
      />
    </>
  )
}
