import React from 'react'

export const createContext = <T>(displayName: string): readonly [() => T, React.Provider<T>] => {
  const context = React.createContext<T | undefined>(undefined)
  context.displayName = displayName
  const useContext = (): T => {
    const value = React.useContext(context)
    if (value === undefined) {
      throw new Error(`Value was not provided for context ${displayName}`)
    }
    return value
  }
  return [useContext, context.Provider as React.Provider<T>] as const
}
