import React, { FC, MouseEvent, useState } from 'react'
import { Menu, MenuItem, SxProps, Theme } from '@mui/material'

import { LoggedInUser, UserData } from '../../providers/LoginProvider'
import { UserAvatar } from '../UserAvatar/UserAvatar'

type LoggedInAvatarProps = {
  user: LoggedInUser
  logout: () => void
  sx?: SxProps<Theme>
}

const LoggedInAvatar: FC<LoggedInAvatarProps> = ({ user, logout, sx }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const { firstName, lastName, photoUrl } = user

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <UserAvatar
        alt={`${firstName} ${lastName}`}
        photoUrl={photoUrl}
        onClick={handleClick}
        sx={{ cursor: 'pointer', ...sx }}
      />
      <Menu
        id="logout-menu"
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl !== null}
        onClose={handleClose}
      >
        <MenuItem onClick={logout}>Log out</MenuItem>
      </Menu>
    </>
  )
}

type GoogleAvatarProps = {
  user: UserData
  logout: () => void
  sx?: SxProps<Theme>
}

export const GoogleAvatar: FC<GoogleAvatarProps> = ({ user, logout, sx }) =>
  user.loggedIn ? <LoggedInAvatar user={user} logout={logout} sx={sx} /> : <UserAvatar sx={sx} />
