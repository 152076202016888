import React, { FC, useState } from 'react'
import { Box, Button, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import { Navigation } from '../components/Navigation/Navigation'
import { AppendUserBlocks, useUserBlocks } from '../hooks/userBlocks'
import { BlockModal } from '../components/BlockModal/BlockModal'
import { UserBlock } from '../models/user'
import { BannedUserTable } from '../components/BannedUserTable/BannedUserTable'

const containsText = (userBlock: UserBlock, text: string) => {
  const fields = [userBlock.user.nick, userBlock.block.reason, userBlock.block.blockingUserNick]
  return fields.some(field => field.toLowerCase().includes(text.toLowerCase()))
}

// Components

const SearchField = ({ value, setValue }: { value: string; setValue: (value: string) => void }) => (
  <TextField
    sx={{ margin: '0 24px 24px 24px' }}
    label="Search for user"
    value={value}
    onChange={event => setValue(event.target.value)}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      )
    }}
    variant="outlined"
  />
)

const Header = ({ appendUserBlocks }: { appendUserBlocks: AppendUserBlocks }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <Box
      sx={{
        mx: 3,
        my: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Typography component="h1" variant="h2" sx={{ fontSize: '22px' }}>
        Banned users
      </Typography>
      <Button
        onClick={() => setModalOpen(true)}
        sx={{ fontSize: '14px' }}
        size="small"
        variant="contained"
      >
        Ban User
      </Button>
      <BlockModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        onSuccess={(userBlock: UserBlock) => {
          appendUserBlocks([userBlock])
          setModalOpen(false)
        }}
      />
    </Box>
  )
}

export const BannedUsersPage: FC = () => {
  const { userBlocks, removeUserBlocks, appendUserBlocks } = useUserBlocks()
  const [searchValue, setSearchValue] = useState<string>('')
  const filteredUserBlocks = userBlocks.filter(userBlock => containsText(userBlock, searchValue))
  return (
    <Navigation currentRoute="bannedUsers">
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          border: '1px solid #79747E',
          borderRadius: '16px',
          boxShadow: 'none',
          overflow: 'hidden'
        }}
      >
        <Header appendUserBlocks={appendUserBlocks} />
        <SearchField value={searchValue} setValue={setSearchValue} />
        <BannedUserTable userBlocks={filteredUserBlocks} removeUserBlocks={removeUserBlocks} />
      </Paper>
    </Navigation>
  )
}
