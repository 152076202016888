import { differenceInMinutes } from 'date-fns'
import { APP_LOCALE } from './localization'

export const dateToApiFormat = (date: Date): string => {
  const d = date.toISOString().split('.')[0]
  return `${d}+00:00`
}

export const formatTimestamp = (
  date: Date,
  formatOptions: Partial<Intl.DateTimeFormatOptions> = {}
): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Helsinki',
    ...formatOptions
  }
  try {
    return new Intl.DateTimeFormat(APP_LOCALE, options).format(date)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('This browser does not support Intl.DateTimeFormat options')
    // eslint-disable-next-line no-console
    console.error(e)
    return date.toISOString()
  }
}

/**
 * Format timestamp relatively, e.g. "2 min", "1 h", "3 d" etc.
 */
export const formatRelativeTimestamp = (date: Date): string => {
  const minutes = differenceInMinutes(new Date(), date, { roundingMethod: 'ceil' })
  if (minutes >= 1440) {
    return `${Math.floor(minutes / 1440)} d`
  } else if (minutes >= 60) {
    return `${Math.floor(minutes / 60)} h`
  }
  return `${minutes} min`
}
