import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { adminUserRoles, AdminUserRoles } from '../../models/user'
import { Toggle } from './Toggle'

type RolesProps = {
  roles: AdminUserRoles
  setRoles: React.Dispatch<React.SetStateAction<AdminUserRoles>>
}

const roleLabels = {
  isCommentator: 'Commentator',
  isModerator: 'Moderator',
  isOperator: 'Operator'
}

const roleInfos = {
  isCommentator: 'User can comment on selected topics without moderation.',
  isModerator: 'User can moderate selected topics.',
  isOperator: 'User can edit access rights of other users.'
}

export const Roles: FC<RolesProps> = ({ roles, setRoles }) => (
  <Box sx={{ marginBottom: '24px' }}>
    <Typography variant="h6" sx={{ fontSize: '16px', marginBottom: '8px' }}>
      Role
    </Typography>
    <Box
      sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, marginBottom: '8px' }}
    >
      {adminUserRoles.map(role => {
        const selected = roles[role]
        return (
          <Toggle
            key={`${role}-toggle`}
            selected={selected}
            label={roleLabels[role]}
            onClick={() => setRoles(roles => ({ ...roles, [role]: !selected }))}
          />
        )
      })}
    </Box>
    {!roles.isCommentator && !roles.isModerator && !roles.isOperator && (
      <Typography variant="body2" sx={{ color: 'error.main' }}>
        At least one role is required.
      </Typography>
    )}
    {adminUserRoles.map(role =>
      roles[role] ? (
        <Typography key={`${role}-info`} variant="body2">
          {roleInfos[role]}
        </Typography>
      ) : null
    )}
  </Box>
)
