import React, { FC, useState } from 'react'
import { Box, Typography } from '@mui/material'

type QuoteProps = {
  author: string
  content: string
}

export const Quote: FC<QuoteProps> = ({ author, content }) => {
  const [isShowMore, setIsShowMore] = useState<boolean>(false)
  const shortContent = content.slice(0, 80)

  return (
    <Box
      sx={{
        borderLeft: '2px solid',
        borderColor: 'text.disabled',
        paddingLeft: 2,
        margin: '8px 0'
      }}
    >
      <Typography sx={{ fontWeight: 500 }}>{author}</Typography>
      <Box>
        <Typography sx={{ display: 'inline', fontStyle: 'italic', whiteSpace: 'pre-wrap' }}>
          {isShowMore ? content : shortContent}
        </Typography>
        {!isShowMore && shortContent !== content && (
          <>
            <Typography sx={{ display: 'inline' }}>...</Typography>
            <Typography
              sx={{
                display: 'inline',
                fontWeight: 500,
                cursor: 'pointer',
                padding: 0,
                marginLeft: 0.5
              }}
              onClick={() => setIsShowMore(true)}
            >
              Show more
            </Typography>
          </>
        )}
      </Box>
    </Box>
  )
}
