import { useEffect, useState } from 'react'
import * as api from '../api/comments-api'
import { UserCommentStats } from '../models/user'
import { useEndpoint } from './endpoint'

type UseUserCommentStats = {
  userId?: string
}

const defaultStats: UserCommentStats = {
  accepted: 0,
  rejected: 0,
  unmoderated: 0
}

export const useUserCommentStats = ({ userId }: UseUserCommentStats): UserCommentStats => {
  const [userCommentStats, setUserCommentStats] = useState<UserCommentStats>(defaultStats)
  const getUserCommentStats = useEndpoint(api.getUserCommentStats)

  useEffect(() => {
    const fetch = async () => {
      if (userId) {
        const stats = await getUserCommentStats({ userId })
        setUserCommentStats(stats)
      } else {
        setUserCommentStats(defaultStats)
      }
    }
    fetch()
  }, [getUserCommentStats, userId])

  return userCommentStats
}
