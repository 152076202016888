import React, { FC } from 'react'
import { Box, SvgIcon, SvgIconProps, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const YleLogo: FC<SvgIconProps> = props => (
  <SvgIcon
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.134 1.39143V28.6085C30.134 29.3503 29.5066 30 28.8188 30H2.2399C1.49315 29.9986 0.865723 29.3503 0.865723 28.6073V1.39143C0.865723 0.619438 1.49315 0 2.2399 0H28.8188C29.5066 0 30.134 0.619438 30.134 1.39143ZM12.2107 10.683H11.7385C11.2202 10.683 11.0444 11.0157 10.9173 11.5865L9.5996 17.4587H9.3738C8.88882 17.4757 8.66814 17.4074 8.51031 16.7617L7.1759 11.5799L7.16179 11.5221C7.06042 11.1289 6.94623 10.6817 6.41375 10.6817H5.91463C5.45914 10.6817 5.19867 10.9382 5.19867 11.3866C5.19867 11.6339 5.26283 11.9981 5.4014 12.536L6.81792 17.7151C7.08095 18.7291 7.64165 19.1815 8.63733 19.1815H9.25065C9.1698 19.6155 9.06845 20.1087 9.00684 20.3073C8.75792 21.099 8.38453 21.2437 7.73917 21.2437C7.16948 21.2437 6.79868 21.199 6.52794 21.1661L6.50485 21.1635C6.34575 21.1451 6.22001 21.1306 6.10068 21.1306C5.72217 21.1306 5.49635 21.3726 5.49635 21.7789V22.1209C5.49635 22.5483 5.71191 22.7022 5.94414 22.8034C6.29314 22.9652 6.72682 23.0534 8.01758 23.0534C9.34043 23.0534 10.3207 22.3247 10.6415 21.1043C11.1919 19.0079 12.6623 12.686 12.7252 12.4164C12.7329 12.3887 12.8997 11.7154 12.8997 11.3879C12.8984 10.9671 12.6213 10.683 12.2107 10.683ZM15.7546 6.32721H14.5613C14.3432 6.32721 13.6785 6.32721 13.6785 7.0032V7.54507C13.6785 7.7568 13.7697 8.24865 14.6165 8.24865H14.8166V18.3332C14.8166 18.9093 14.9462 19.267 15.5877 19.267H15.9765C16.6322 19.267 16.7207 18.7948 16.7207 18.3332V7.74495C16.7207 7.21363 16.7207 6.32721 15.7546 6.32721ZM24.9581 17.3732C24.8336 17.3732 24.7079 17.3982 24.5616 17.4272L24.5065 17.4377C24.2114 17.4981 23.7161 17.5994 22.9334 17.5994C20.9061 17.5994 20.6675 17.2483 20.6033 15.6214H24.4795C25.3738 15.6214 25.7728 15.2611 25.7728 14.4536C25.7728 12.7373 25.4212 10.4528 22.3791 10.4528C19.8104 10.4528 18.6646 11.8824 18.6646 15.0875C18.6646 18.2162 19.7321 19.3801 22.6023 19.3801C23.7956 19.3801 24.7399 19.1921 25.1108 19.0184C25.3468 18.9159 25.5637 18.762 25.5637 18.3332V18.0203C25.5624 17.6165 25.3366 17.3732 24.9581 17.3732Z"
      fill="white"
    />
    <g transform="translate(20 12)">
      <path
        d="M0.61377 2.02328C0.688184 0.62926 1.24377 0.00585938 2.4088 0.00585938H2.41004C3.5174 0.00585938 4.01518 0.609535 4.06655 2.02066L0.61377 2.02328Z"
        fill="white"
      />
    </g>
  </SvgIcon>
)

export const CommentsAdminLogo = () => {
  return (
    <Link to="/">
      <Box
        sx={{
          mt: 2,
          display: { xs: 'none', sm: 'none', md: 'flex' },
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <YleLogo sx={{ mb: 1, fontSize: '32px' }} />
        <Typography sx={{ color: 'white', textAlign: 'center', fontSize: 14 }}>
          Comments Admin
        </Typography>
      </Box>
    </Link>
  )
}
