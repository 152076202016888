import type { ReactNode } from 'react'
import type { OptionsObject, SnackbarKey } from 'notistack'

type Enqueue = (message: ReactNode, options?: OptionsObject) => SnackbarKey
type Variant = 'success' | 'error'
const errorAutoHideDuration = 40000
const successAutoHideDuration = 3000

function showSnackbar(enqueue: Enqueue, variant: 'success', message: ReactNode): SnackbarKey
function showSnackbar(enqueue: Enqueue, variant: 'error', error: Error): SnackbarKey
function showSnackbar(
  enqueue: Enqueue,
  variant: Variant,
  msgOrErr: ReactNode | Error
): SnackbarKey {
  const message = msgOrErr instanceof Error ? msgOrErr.message : msgOrErr
  const autoHideDuration = variant === 'success' ? successAutoHideDuration : errorAutoHideDuration
  return enqueue(message, { variant, autoHideDuration })
}

export { showSnackbar }

export const wrapError = (err: unknown): Error => {
  if (err instanceof Error) {
    return err
  }
  return new Error('Unknown error', { cause: err })
}
