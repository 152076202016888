import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { Box, Button, Divider, List, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { Topic } from '../../models/topic'
import { countUnprocessedItems } from '../../utils/topic'
import { TopicListItem } from './TopicListItem'
import { TopicStateFilter, TopicFilter } from './TopicStateFilter'
import { ListContainer } from '../ListContainer/ListContainer'
import { SearchFilter } from '../SearchFilter/SearchFilter'

type DiscussionCountProps = {
  topics: Topic[]
}

const CountItem: FC<PropsWithChildren> = ({ children }) => (
  <Typography sx={{ fontSize: '14px', fontWeight: '500' }} component="span">
    {children}
  </Typography>
)

const DiscussionCount: FC<DiscussionCountProps> = ({ topics }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px'
    }}
  >
    <CountItem>Discussions: {topics.length}</CountItem>
    <CountItem>Unmoderated comments: {countUnprocessedItems(topics)}</CountItem>
  </Box>
)

const filterTopics = (topics: Topic[], filter: string) => {
  if (filter === '') {
    return topics
  }
  const filterFn = ({ title, externalId }: Topic) =>
    title.toLowerCase().includes(filter) || externalId.includes(filter)
  return topics.filter(filterFn)
}

type TopicListProps = {
  topics: Topic[]
  topicFilter: TopicFilter
  setTopicFilter: (filter: TopicFilter) => void
  selectedTopic: string | undefined
  setSelectedTopic: (id: string | undefined) => void
  loadSingleTopic: (id: string) => void
  openAreenaTopicModal?: () => void
}

export const TopicList: FC<TopicListProps> = ({
  topics,
  topicFilter,
  setTopicFilter,
  selectedTopic,
  setSelectedTopic,
  loadSingleTopic,
  openAreenaTopicModal
}) => {
  const [filterValue, setFilterValue] = useState('')
  const filteredTopics = filterTopics(topics, filterValue.toLowerCase())

  useEffect(() => {
    if (filterValue.match(/\d+-\d+/)) {
      loadSingleTopic(filterValue)
    }
  }, [filterValue, loadSingleTopic])

  return (
    <ListContainer>
      <Box sx={{ padding: 3, paddingBottom: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="h2" sx={{ fontSize: '22px', lineHeight: '32.5px' }}>
            Discussions
          </Typography>
          {openAreenaTopicModal !== undefined && (
            <Button
              variant="outlined"
              onClick={openAreenaTopicModal}
              startIcon={<Add />}
              sx={{ ml: 2 }}
            >
              Add
            </Button>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <TopicStateFilter filter={topicFilter} onChange={setTopicFilter} />
        </Box>
        <SearchFilter
          id="topic-filter"
          placeholder="Filter discussions..."
          value={filterValue}
          onChange={setFilterValue}
          fullWidth
        />
        <DiscussionCount topics={filteredTopics} />
      </Box>
      <Divider />
      <List sx={{ padding: 0, flex: 1, overflowY: 'auto' }}>
        {filteredTopics.map(topic => {
          const isSelected = topic.id === selectedTopic
          return (
            <TopicListItem
              key={topic.id}
              topic={topic}
              isSelected={isSelected}
              onClick={isSelected ? undefined : () => setSelectedTopic(topic.id)}
            />
          )
        })}
      </List>
    </ListContainer>
  )
}
