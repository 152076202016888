import { useEffect, useState } from 'react'
import * as api from '../api/comments-api'
import { useEndpoint } from './endpoint'
import { AutomoderationLimits } from '../models/automoderationLimits'

export const useAutomoderationLimits = () => {
  const [autoModerationLimits, setAutoModerationLimits] = useState<AutomoderationLimits | null>(
    null
  )
  const getAutomoderationLimits = useEndpoint(api.getAutomoderationLimits)

  useEffect(() => {
    getAutomoderationLimits().then(setAutoModerationLimits)
  }, [getAutomoderationLimits])

  return autoModerationLimits
}
