import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import jwtDecode from 'jwt-decode'
import { isPast } from 'date-fns'
import { checkToken } from '../api/comments-api'
import { ApiOptions, useApiOptions } from '../providers/ApiOptionsProvider'
import type { ErrorUser, LoggedOutUser, LoginData, UserData } from '../providers/LoginProvider'
import { showSnackbar, wrapError } from './snackbar'
import { isError } from './user'
import { useInterval } from '../hooks/interval'

/* eslint-disable no-empty */

const STORAGE_KEY = 'google_token'

export type GoogleUser = {
  aud: string
  sub: string
  hd: string
  email: string
  picture: string
  given_name: string
  family_name: string
  exp: number
}

type Token = {
  token: string
  expires: number
}

type UserWithToken = {
  user: UserData
  token?: Token
}

const loggedOutUser: LoggedOutUser = {
  type: 'loggedOut',
  loggedIn: false
}

const errorUser = (error: Error): ErrorUser => ({
  type: 'error',
  loggedIn: false,
  error
})

const authenticateUser = async (token: string, apiOptions: ApiOptions): Promise<UserWithToken> => {
  const user = jwtDecode<GoogleUser>(token)
  const expires = user.exp
  const backendUser = await checkToken(apiOptions, token)
  return {
    user: {
      type: 'loggedIn',
      loggedIn: true,
      id: user.sub,
      firstName: user.given_name,
      lastName: user.family_name,
      email: user.email,
      photoUrl: user.picture,
      ...backendUser
    },
    token: { token, expires }
  }
}

const removeStoredToken = () => {
  try {
    sessionStorage.removeItem(STORAGE_KEY)
  } catch {}
}

export const storeToken = (token: Token) => {
  try {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(token))
  } catch {}
}

export const getStoredToken = (): Token | null => {
  try {
    const item = sessionStorage.getItem(STORAGE_KEY)
    if (item) {
      return JSON.parse(item) as Token
    }
  } catch {}
  return null
}

const hasExpired = (expires: number): boolean => isPast(expires * 1000)
const isAboutToExpire = (expires: number): boolean => isPast((expires - 60) * 1000)

const loginWithStoredToken = async (apiOptions: ApiOptions): Promise<UserWithToken | null> => {
  try {
    const item = getStoredToken()
    if (item && !hasExpired(item.expires)) {
      return await authenticateUser(item.token, apiOptions)
    }
  } catch {}
  removeStoredToken()
  return null
}

export const useGoogleAuth = (): LoginData => {
  const apiOptions = useApiOptions()
  const { enqueueSnackbar } = useSnackbar()
  const [userAndToken, setUserAndToken] = useState<UserWithToken>({
    user: {
      type: 'loading',
      loggedIn: false
    }
  })

  const logout = useCallback(() => {
    setUserAndToken({ user: loggedOutUser })
    window.google.accounts.id.disableAutoSelect()
    removeStoredToken()
  }, [])

  useEffect(() => {
    const effect = async () => {
      setUserAndToken({ user: loggedOutUser })
      window.google.accounts.id.initialize({
        client_id: apiOptions.googleClientId,
        auto_select: true,
        hd: 'yle.fi',
        callback: async response => {
          try {
            const userAndToken = await authenticateUser(response.credential, apiOptions)
            setUserAndToken(userAndToken)
            const token = userAndToken.token
            if (token) {
              storeToken(token)
            }
          } catch (err) {
            setUserAndToken({
              user: errorUser(wrapError(err))
            })
          }
        }
      })
      const userAndToken = await loginWithStoredToken(apiOptions)
      if (userAndToken) {
        setUserAndToken(userAndToken)
      } else {
        window.google.accounts.id.prompt()
      }
    }
    effect()
  }, [apiOptions])

  useInterval(() => {
    const { token } = userAndToken
    if (token && isAboutToExpire(token.expires)) {
      window.google.accounts.id.prompt()
    }
  }, 10000)

  useEffect(() => {
    const { user } = userAndToken
    if (isError(user)) {
      showSnackbar(enqueueSnackbar, 'error', user.error)
    }
  }, [userAndToken, enqueueSnackbar])

  return {
    user: userAndToken.user,
    token: userAndToken.token?.token,
    logout
  }
}

export const GoogleLoginButton: FC = () => {
  const renderButton = (elem: HTMLDivElement | null) => {
    if (elem !== null) {
      window.google.accounts.id.renderButton(elem, { type: 'standard', size: 'large' })
    }
  }
  return <div ref={renderButton} />
}
