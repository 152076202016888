import React, { ReactNode, useEffect, useState } from 'react'
import { Search } from '@mui/icons-material'
import { TextField, InputAdornment, Autocomplete } from '@mui/material'
import { useDebounce } from '../hooks/debounce'

/* Component for searching for users by nickname */

type UserSearchProps<T> = {
  defaultValue: string
  label: string
  autoSelect?: boolean
  errorText?: string
  onSelect: (value: T | undefined) => void
  onSearch: (s: string) => Promise<T[]>
  getOptionLabel: (value: string | T) => string
  renderOption: (props: React.HTMLAttributes<HTMLLIElement>, value: T) => ReactNode
}

export const UserSearch = <T,>({
  defaultValue,
  label,
  autoSelect,
  errorText,
  onSelect,
  onSearch,
  getOptionLabel,
  renderOption
}: UserSearchProps<T>): JSX.Element => {
  const [searchResults, setSearchResults] = useState<T[]>([])
  const [searchValue, setSearchValue] = useState<string>(defaultValue)
  const debouncedValue = useDebounce<string>(searchValue)

  useEffect(() => {
    if (!debouncedValue) {
      setSearchResults([])
    } else {
      onSearch(debouncedValue).then(setSearchResults)
    }
  }, [debouncedValue, onSearch])

  return (
    <Autocomplete<T, false, false, true>
      freeSolo
      autoSelect={autoSelect}
      defaultValue={defaultValue}
      options={searchResults}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      fullWidth
      id="block-modal-search-field"
      onChange={(event, value) => onSelect(value as T)}
      onInputChange={(event, value) => setSearchValue(value)}
      renderInput={params => {
        return (
          <TextField
            {...params}
            size="small"
            label={label}
            error={Boolean(errorText)}
            helperText={errorText}
            inputProps={{ ...params.inputProps, 'data-testid': 'block-modal-search-field' }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            variant="outlined"
          />
        )
      }}
      sx={{ mt: 3, mb: 3 }}
    />
  )
}
