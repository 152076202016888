import React, { FC, useState } from 'react'
import { RenderComment } from '../Comment/CommentListItem'
import {
  AiModeratorLoveEyesIcon,
  AiModeratorCircleEyesIcon,
  AiModeratorCrossEyesIcon
} from './AiModeratorIcon'
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  SvgIconProps,
  Tooltip,
  Typography
} from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import * as api from '../../api/comments-api'
import { useEndpoint } from '../../hooks/endpoint'
import { AutomoderationLimits } from '../../models/automoderationLimits'
import { palette } from '../../styles/colors'

type AiModerationProps = {
  comment: RenderComment
  automoderationLimits: AutomoderationLimits | null
}

type TrafficLightColor = 'green' | 'yellow' | 'red' | 'grey'

const fontSettings = { fontSize: '11px', lineHeight: '16px' }

const getTrafficLight = (
  limits: AutomoderationLimits | null,
  label: number | null
): { color: TrafficLightColor; Icon: React.FC<SvgIconProps> } => {
  if (limits === null || label === null) {
    return { color: 'grey', Icon: AiModeratorCircleEyesIcon }
  }
  if (label < limits.yellowMin) {
    return { color: 'green', Icon: AiModeratorLoveEyesIcon }
  }
  if (label > limits.yellowMax) {
    return { color: 'red', Icon: AiModeratorCrossEyesIcon }
  }
  return { color: 'yellow', Icon: AiModeratorCircleEyesIcon }
}

const TrafficLight: FC<{ color: TrafficLightColor }> = ({ color }) => {
  const colors: Record<TrafficLightColor, string> = {
    green: 'success.main',
    yellow: 'warning.main',
    red: 'error.main',
    grey: palette.grey.normal
  }
  return (
    <Box
      sx={{
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: colors[color]
      }}
    />
  )
}

export const AiModeration: FC<AiModerationProps> = ({ comment, automoderationLimits }) => {
  const putAIFeedback = useEndpoint(api.putAIFeedback)
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)
  const [feedback, setFeedback] = useState<TrafficLightColor | null>(null)

  const onSendFeedback = (feedback: TrafficLightColor) => {
    putAIFeedback({ topicId: comment.topicId, commentId: comment.id, feedback })
    setFeedback(feedback)
  }

  if (comment.aiModerationStatus === null) {
    return null
  }

  const trafficLight = getTrafficLight(automoderationLimits, comment.aiModerationLabel)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px'
        }}
      >
        <trafficLight.Icon />
        <Typography sx={{ fontWeight: 500, ...fontSettings }}>AI moderator:</Typography>
        {comment.aiModerationLabel !== null && trafficLight && (
          <>
            <TrafficLight color={trafficLight.color} />
            <Typography sx={fontSettings}>
              {`${Math.round((1 - comment.aiModerationLabel) * 100)} % eligible`}
            </Typography>
          </>
        )}
        {(comment.aiModerationStatus === 'pending' ||
          comment.aiModerationStatus === 'in_progress') && (
          <>
            <CircularProgress size={10} sx={{ color: 'primary.main' }} />
            <Typography sx={fontSettings}>loading...</Typography>
          </>
        )}
        {comment.aiModerationStatus === 'error' && (
          <>
            <Typography sx={fontSettings}>failed to moderate this message</Typography>
          </>
        )}
        <Tooltip
          title="AI moderation, powered by large language models (LLMs), 
          assesses messages and categorizes them using a traffic light system: green for accepted, 
          yellow for debatable, and red for rejected messages. By analyzing language patterns, it 
          identifies content that may contain offensive language, spam, or harmful material, aiding 
          in maintaining a safe and respectful discussion forum."
          placement="bottom"
        >
          <InfoOutlined sx={{ cursor: 'pointer' }} fontSize="small" />
        </Tooltip>
      </Box>
      <Box
        sx={{
          marginBottom: '8px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '16px'
        }}
      >
        {isFeedbackOpen ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              alignItems: 'center',
              padding: '2px 2px 2px 0'
            }}
          >
            <Typography sx={fontSettings}>Correct result:</Typography>
            {feedback === null ? (
              <ButtonGroup variant="contained">
                <IconButton onClick={() => onSendFeedback('green')} size="small">
                  <TrafficLight color="green" />
                </IconButton>
                <IconButton onClick={() => onSendFeedback('yellow')} size="small">
                  <TrafficLight color="yellow" />
                </IconButton>
                <IconButton onClick={() => onSendFeedback('red')} size="small">
                  <TrafficLight color="red" />
                </IconButton>
              </ButtonGroup>
            ) : (
              <>
                <TrafficLight color={feedback} />
                <Typography sx={{ padding: '2px 2px 2px 0', ...fontSettings }}>
                  Your feedback is saved. Thank you!
                </Typography>
              </>
            )}
          </Box>
        ) : (
          <Button
            sx={{
              color: 'rgba(0, 0, 0, 0.87)',
              fontWeight: 400,
              textDecoration: 'underline',
              textTransform: 'none',
              paddingLeft: 0,
              ...fontSettings
            }}
            variant="text"
            onClick={() => setIsFeedbackOpen(true)}
            size="small"
          >
            Report as incorrect
          </Button>
        )}
      </Box>
    </Box>
  )
}
