import React, { FC, useEffect, useState } from 'react'
import { Autocomplete, Button, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useSnackbar } from 'notistack'
import { Modal } from '../Modal'
import { useTags } from '../../hooks/tags'
import { Tag } from '../../models/tag'
import { showSnackbar, wrapError } from '../../utils/snackbar'
import { useEndpoint } from '../../hooks/endpoint'
import * as api from '../../api/comments-api'
import { NavigateFunction } from 'react-router-dom'
import { routes } from '../../constants/routes'
import { isAreenaTag } from '../../utils/util'
import { Topic } from '../../models/topic'

type AreenaTopicModalProps = {
  open: boolean
  topic?: Topic
  handleClose: () => void
  navigate: NavigateFunction
}

const A = styled('a')({
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#21005D'
})

const tagIds = (tags: Tag[]) => tags.map(tag => tag.id)

export const AreenaTopicModal: FC<AreenaTopicModalProps> = ({
  handleClose,
  open,
  topic,
  navigate
}) => {
  const [externalId, setExternalId] = useState('')
  const [tags, setTags] = useState<Tag[]>([])
  const [openedAt, setOpenedAt] = useState<Date | null>(null)
  const [lockedAt, setLockedAt] = useState<Date | null>(null)
  const [title, setTitle] = useState('')

  useEffect(() => {
    setExternalId(topic?.externalId || '')
    setTags(topic?.tags || [])
    setOpenedAt(topic?.openedAt || null)
    setLockedAt(topic?.lockedAt || null)
    setTitle(topic?.title || '')
  }, [open, topic])

  const allTags = useTags()
  const areenaTags = allTags.filter(isAreenaTag)
  const addTag = (tag: Tag) =>
    setTags(oldTags => (oldTags.includes(tag) ? oldTags : [tag, ...oldTags]))

  const { enqueueSnackbar } = useSnackbar()
  const saveTopic = useEndpoint(api.saveTopic)
  const updateTopic = useEndpoint(api.updateTopic)
  const updateTags = useEndpoint(api.updateTags)
  const isValidId = externalId.match(/^\d-\d+$/) != null
  const isValidTopic = isValidId && tags.length > 0 && title.length > 0

  return (
    <Modal
      open={open}
      title={topic === undefined ? 'Add new Areena discussion' : 'Edit Areena discussion'}
      handleClose={handleClose}
      actions={
        <>
          <Button
            variant="contained"
            disabled={!isValidTopic}
            onClick={async () => {
              try {
                let savedTopic: Topic
                if (topic !== undefined) {
                  await updateTags({
                    topicId: topic.id,
                    oldTagIds: tagIds(topic.tags),
                    newTagIds: tagIds(tags)
                  })
                  savedTopic = await updateTopic({
                    id: topic.id,
                    externalId,
                    openedAt,
                    lockedAt,
                    title,
                    url: `https://areena.yle.fi/podcastit/${externalId}`
                  })
                } else {
                  savedTopic = await saveTopic({
                    externalId,
                    tagIds: tagIds(tags),
                    openedAt,
                    lockedAt,
                    title,
                    url: `https://areena.yle.fi/podcastit/${externalId}`
                  })
                }
                showSnackbar(enqueueSnackbar, 'success', 'Topic saved')
                handleClose()
                navigate(`${routes.moderation}/${savedTopic.id}`)
              } catch (e) {
                showSnackbar(enqueueSnackbar, 'error', wrapError(e))
              }
            }}
            sx={{ ml: 2 }}
          >
            Save
          </Button>
        </>
      }
    >
      <p>Discussions for articles can be opened from Yddrasil.</p>
      <TextField
        label="Areena ID"
        helperText="Episode's Areena ID, eg. 1-66245672"
        value={externalId}
        error={externalId !== '' && !isValidId}
        onChange={e => setExternalId(e.currentTarget.value)}
        fullWidth
      />
      <Autocomplete
        multiple
        options={allTags}
        getOptionLabel={tag => tag.name}
        isOptionEqualToValue={(tag1, tag2) => tag1.id === tag2.id}
        renderInput={props => (
          <TextField
            {...props}
            fullWidth
            label="Moderation group"
            helperText={
              <>
                Select a moderation group. This affects who can moderate the discussion.
                <br />
                Suggested groups:{' '}
                {areenaTags.map((tag, i) => (
                  <React.Fragment key={tag.externalId}>
                    <A role="button" onClick={() => addTag(tag)}>
                      {tag.name}
                    </A>
                    {i < areenaTags.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </>
            }
            sx={{ mt: 2 }}
          />
        )}
        value={tags}
        onChange={(e, value) => setTags(value)}
      />
      <DateTimePicker
        label="Start time"
        value={openedAt}
        onChange={(value: Date | null) => setOpenedAt(value)}
        slotProps={{ field: { clearable: true }, textField: { fullWidth: true } }}
        sx={{ mt: 2 }}
      />
      <DateTimePicker
        label="End time"
        value={lockedAt}
        onChange={(value: Date | null) => setLockedAt(value)}
        slotProps={{ field: { clearable: true }, textField: { fullWidth: true } }}
        sx={{ mt: 2 }}
      />
      <TextField
        label="Discussion description"
        helperText="Add your discussion topic or description here. This text will be shown on episode page above the discussion button."
        value={title}
        onChange={e => setTitle(e.currentTarget.value)}
        fullWidth
        sx={{ mt: 2 }}
      />
    </Modal>
  )
}
