import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { FC } from 'react'
import type { RoleNameOrAll, UserCount } from '../../hooks/user'
import { commentFilterTabSx } from '../Comment/CommentFilterTabs'

type RoleBarProps = {
  userCount: UserCount
  value: RoleNameOrAll
  setValue: (value: RoleNameOrAll) => void
}

const buttonSx = {
  ...commentFilterTabSx,
  flex: {
    xs: '0 0 50%',
    sm: '0 0 auto'
  }
}

export const RoleBar: FC<RoleBarProps> = ({ userCount, value, setValue }) => (
  <ToggleButtonGroup
    value={value}
    exclusive
    onChange={(e, value) => {
      if (value !== null) {
        setValue(value as RoleNameOrAll)
      }
    }}
    color="primary"
    sx={{ mx: 3, flexWrap: 'wrap' }}
  >
    <ToggleButton sx={buttonSx} value="all">{`All users (${userCount.all})`}</ToggleButton>
    <ToggleButton
      sx={buttonSx}
      value="isCommentator"
    >{`Commentators (${userCount.isCommentator})`}</ToggleButton>
    <ToggleButton
      sx={buttonSx}
      value="isModerator"
    >{`Moderators (${userCount.isModerator})`}</ToggleButton>
    <ToggleButton
      sx={buttonSx}
      value="isOperator"
    >{`Operators (${userCount.isOperator})`}</ToggleButton>
  </ToggleButtonGroup>
)
