import React, { FC } from 'react'
import { IconButton, InputAdornment, SxProps, TextField } from '@mui/material'
import { Clear, Search } from '@mui/icons-material'

type SearchFilterProps = {
  id?: string
  value: string
  onChange: (value: string) => void
  placeholder?: string
  sx?: SxProps
  fullWidth?: boolean
}

export const SearchFilter: FC<SearchFilterProps> = ({
  id,
  placeholder,
  value,
  onChange,
  fullWidth,
  sx
}) => (
  <TextField
    id={id}
    placeholder={placeholder}
    variant="outlined"
    size="small"
    value={value}
    onChange={e => onChange(e.currentTarget.value)}
    fullWidth={fullWidth}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
      endAdornment: value ? (
        <InputAdornment position="end">
          <IconButton onClick={() => onChange('')}>
            <Clear />
          </IconButton>
        </InputAdornment>
      ) : undefined
    }}
    sx={{
      marginTop: 2,
      marginBottom: 2,
      ...sx
    }}
  />
)
