import React, { FC, ReactNode, useState } from 'react'
import { Box, Container } from '@mui/material'
import { SideDrawer } from './SideDrawer'
import { useLogin } from '../../providers/LoginProvider'
import { Route } from '../../constants/routes'
import { TopBar } from './TopBar'
import { palette } from '../../styles/colors'

type NavigationProps = {
  children?: ReactNode
  currentRoute?: Route
}

export const Navigation: FC<NavigationProps> = ({ children = null, currentRoute }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { user } = useLogin()

  return (
    <Box sx={{ display: 'flex', background: palette.grey.lighter, minHeight: '100vh' }}>
      <TopBar titleText="Comments Admin" onClickMenu={() => setIsDrawerOpen(open => !open)} />
      {user.loggedIn && <SideDrawer currentRoute={currentRoute} isOpen={isDrawerOpen} />}
      <Container
        maxWidth="xl"
        sx={{
          padding: { xs: '56px 0 0', sm: '56px 24px 0', md: '24px 24px 0', height: '100vh' },
          display: 'flex'
        }}
      >
        {children}
      </Container>
    </Box>
  )
}
