import { useEffect, useMemo, useState } from 'react'
import { getAdminUsers, getUser } from '../api/comments-api'
import type { AdminUser, RoleName, User } from '../models/user'
import { compareUsers } from '../utils/user'
import { useEndpoint } from './endpoint'

type UseUser = {
  userId?: string
}

export const useUser = ({ userId }: UseUser): User | undefined => {
  const fetchUser = useEndpoint(getUser)
  const [user, setUser] = useState<User | undefined>()

  useEffect(() => {
    if (userId) {
      fetchUser({ userId }).then(setUser)
    } else {
      setUser(undefined)
    }
  }, [userId, fetchUser])

  return user
}

export type RoleNameOrAll = RoleName | 'all'
export type UserCount = Record<RoleNameOrAll, number>

export type UseAdminUsers = {
  users: AdminUser[]
  userCount: UserCount
  updateUser: (user: AdminUser) => void
}

const emptyCount: UserCount = {
  all: 0,
  isOperator: 0,
  isModerator: 0,
  isCommentator: 0
}

const countUsers = (users: AdminUser[]): UserCount =>
  users.reduce(
    (count, user) => ({
      all: count.all + 1,
      isOperator: count.isOperator + (user.isOperator ? 1 : 0),
      isModerator: count.isModerator + (user.isModerator ? 1 : 0),
      isCommentator: count.isCommentator + (user.isCommentator ? 1 : 0)
    }),
    emptyCount
  )

const updateUserInList = (users: AdminUser[], user: AdminUser): AdminUser[] => {
  if (!user.isActive) {
    return users.filter(({ id }) => id !== user.id)
  }
  const index = users.findIndex(({ id }) => id === user.id)
  if (index === -1) {
    return [user, ...users].sort(compareUsers)
  }
  return [...users.slice(0, index), user, ...users.slice(index + 1)]
}

export const useAdminUsers = (): UseAdminUsers => {
  const fetchAdminUsers = useEndpoint(getAdminUsers)
  const [users, setUsers] = useState<AdminUser[]>([])
  const userCount = useMemo(() => countUsers(users), [users])
  const updateUser = (user: AdminUser) => {
    setUsers(users => updateUserInList(users, user))
  }

  useEffect(() => {
    fetchAdminUsers().then(users => {
      users.sort(compareUsers)
      setUsers(users)
    })
  }, [fetchAdminUsers])

  return { users, userCount, updateUser }
}
