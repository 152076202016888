import React, { FC, useState } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { RoleBar } from '../components/AdminUserFilter/RoleBar'
import { AdminUserModal } from '../components/AdminUserModal/AdminUserModal'
import { AdminUserTable } from '../components/AdminUserTable/AdminUserTable'
import { SearchFilter } from '../components/SearchFilter/SearchFilter'
import { Navigation } from '../components/Navigation/Navigation'
import { RoleNameOrAll, useAdminUsers } from '../hooks/user'
import { AdminUser } from '../models/user'
import { filterUsers } from '../utils/user'

export const AdminUsersPage: FC = () => {
  const { users, userCount, updateUser } = useAdminUsers()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<AdminUser>()
  const [search, setSearch] = useState('')
  const [role, setRole] = useState<RoleNameOrAll>('all')
  const filteredUsers = filterUsers(users, search, role)
  return (
    <Navigation currentRoute="adminUsers">
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          border: '1px solid #79747E',
          borderRadius: '16px',
          boxShadow: 'none',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            mx: 3,
            mt: 3,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant="h2" sx={{ fontSize: '22px' }}>
            User management
          </Typography>
          <Button
            variant="contained"
            size="small"
            startIcon={<Add />}
            onClick={() => setModalOpen(true)}
          >
            Add user
          </Button>
        </Box>
        <SearchFilter
          id="user-search"
          value={search}
          placeholder="Filter users..."
          onChange={setSearch}
          sx={{ mx: 3 }}
        />
        <RoleBar value={role} setValue={setRole} userCount={userCount} />
        <AdminUserTable
          users={filteredUsers}
          selectUser={(user: AdminUser) => {
            setSelectedUser(user)
            setModalOpen(true)
          }}
        />
        <AdminUserModal
          open={modalOpen}
          user={selectedUser}
          handleClose={() => {
            setSelectedUser(undefined)
            setModalOpen(false)
          }}
          onSuccess={user => {
            updateUser(user)
            setSelectedUser(undefined)
            setModalOpen(false)
          }}
        />
      </Paper>
    </Navigation>
  )
}
