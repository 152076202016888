import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { AdminUser } from '../../models/user'
import { UserAvatar } from '../UserAvatar/UserAvatar'

type UserDetailsProps = {
  user: AdminUser
}

export const UserDetails: FC<UserDetailsProps> = ({ user }) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '24px 0' }}>
    <UserAvatar alt={user.nick} photoUrl={user.photoUrl || undefined} />
    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
      <Typography variant="h5">{user.nick}</Typography>
      <Typography variant="body2">{user.externalId}</Typography>
    </Box>
  </Box>
)

type NewUserDetailsProps = {
  email: string
}

export const NewUserDetails: FC<NewUserDetailsProps> = ({ email }) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '24px 0' }}>
    <UserAvatar />
    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
      <Typography variant="h5">{email}</Typography>
    </Box>
  </Box>
)
