import React, { FC, ReactNode } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { PaletteColorOptions } from '@mui/material'
import { Color, palette } from '../styles/colors'
import { breakpoints } from '../constants/breakpoints'

const convertYleColorToMuiColor = (color: Color): PaletteColorOptions => ({
  light: color.lighter,
  main: color.normal,
  dark: color.darker,
  contrastText: color.contrast
})

export const MuiThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const primaryColor = palette.purple /* TODO: green if not prod */

  const theme = createTheme({
    palette: {
      primary: convertYleColorToMuiColor(primaryColor),
      secondary: convertYleColorToMuiColor(palette.turquoise),
      error: convertYleColorToMuiColor(palette.red),
      warning: convertYleColorToMuiColor(palette.yellow),
      info: convertYleColorToMuiColor(palette.brown),
      success: convertYleColorToMuiColor(palette.green),
      background: {
        default: palette.background
      }
    },
    typography: {
      fontFamily: ['Roboto', 'sans-serif'].join(',')
    },
    breakpoints: {
      values: {
        xs: breakpoints.xs,
        sm: breakpoints.sm,
        md: breakpoints.md,
        lg: breakpoints.lg,
        xl: breakpoints.xl
      }
    }
  })

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
