import React, { FC } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'

type PageInstructionProps = {
  text: string
  showSpinner?: boolean
}

export const PageInstructions: FC<PageInstructionProps> = ({ text, showSpinner = false }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: 3
    }}
  >
    {showSpinner && (
      <CircularProgress
        sx={{
          paddingBottom: 4,
          color: 'text.disabled'
        }}
      />
    )}
    <Typography sx={{ fontSize: '16px' }} variant="body1" color="textSecondary">
      {text}
    </Typography>
  </Box>
)
