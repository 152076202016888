import React, { FC, ReactNode } from 'react'
import { createContext } from '../utils/context'

export type Env = 'local' | 'test' | 'production'

export type ApiOptions = {
  googleClientId: string
  commentsApiUrl: string
  appId: string
  appKey: string
}

const apiOptions: Record<Env, ApiOptions> = {
  local: {
    googleClientId: '231951382760-8p19phk7nuop86q29hsf6hhoq7nu3kdh.apps.googleusercontent.com',
    commentsApiUrl: 'https://local.yle.fi:48309',
    appId: 'api_team_dev_test',
    appKey: '41040fe7aa30a2da50db7f52d31cc6a4'
  },
  test: {
    googleClientId: '231951382760-8p19phk7nuop86q29hsf6hhoq7nu3kdh.apps.googleusercontent.com',
    commentsApiUrl: 'https://comments.api-test.yle.fi',
    appId: 'comments_admin_test',
    appKey: 'dc7f606f0753df6ae60532011b08db3b'
  },
  production: {
    googleClientId: '571871739155-8tadtqs694s3p8j0pc7m3tqtjff02870.apps.googleusercontent.com',
    commentsApiUrl: 'https://comments.api.yle.fi',
    appId: 'comments_admin_prod',
    appKey: 'f5b3c757e155152b6363d14efdbee48b'
  }
}

const getApiOptions = (env: Env): ApiOptions => {
  const opts = apiOptions[env]
  if (opts === undefined) {
    throw new Error(`Invalid env: ${env}. Allowed values: 'production', 'test', 'local'`)
  }
  return opts
}

const [useApiOptions, Provider] = createContext<ApiOptions>('ApiOptionsContext')
export { useApiOptions }

type ApiOptionsProviderProps = {
  env: Env
  children: ReactNode
}

export const ApiOptionsProvider: FC<ApiOptionsProviderProps> = ({ env, children }) => {
  return <Provider value={getApiOptions(env)}>{children}</Provider>
}
