import React, { FC, useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'

export type TopicFilter = 'Open' | 'Closed' | 'Hidden' | 'Upcoming'

type TopicStateFilterProps = {
  filter: TopicFilter
  onChange: (state: TopicFilter) => void
}

export const TopicStateFilter: FC<TopicStateFilterProps> = ({ filter, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isOpen = Boolean(anchorEl)
  const handleClose = () => setAnchorEl(null)
  const handleClick = (state: TopicFilter) => {
    onChange(state)
    handleClose()
  }
  return (
    <div>
      <Button
        onClick={e => setAnchorEl(e.currentTarget)}
        endIcon={<ArrowDropDown />}
        sx={{ textTransform: 'none' }}
      >{`Show: ${filter}`}</Button>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        <MenuItem onClick={() => handleClick('Open')}>Open</MenuItem>
        <MenuItem onClick={() => handleClick('Closed')}>Closed</MenuItem>
        <MenuItem onClick={() => handleClick('Hidden')}>Hidden</MenuItem>
        <MenuItem onClick={() => handleClick('Upcoming')}>Upcoming</MenuItem>
      </Menu>
    </div>
  )
}
