import React, { FC, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { getAdminUser, saveAdminUser, searchAdminUsers } from '../../api/comments-api'
import { useEndpoint } from '../../hooks/endpoint'
import { useTags } from '../../hooks/tags'
import { AdminUser } from '../../models/user'
import { getRoles } from '../../utils/user'
import { Modal } from '../Modal'
import { Roles } from './Roles'
import { Tags } from './Tags'
import { UserDetails, NewUserDetails } from './UserDetails'
import NotFoundError from '../../errors/NotFoundError'
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog'
import { UserSearch } from '../UserSearch'

type AdminUserModalProps = {
  open: boolean
  user?: AdminUser
  handleClose: () => void
  onSuccess: (user: AdminUser) => void
}

const emailRegex = /.+@yle\.fi/

const emailIsInvalid = (email: string) => email.match(emailRegex) === null

const getTagSet = (user?: AdminUser): Set<string> =>
  user === undefined ? new Set() : new Set(user.tags.map(tag => tag.id))

export const AdminUserModal: FC<AdminUserModalProps> = ({ open, user, handleClose, onSuccess }) => {
  const [roles, setRoles] = useState(getRoles(user))
  const [userTags, setUserTags] = useState(getTagSet(user))
  const [email, setEmail] = useState('')

  // null value means that user was searched from backend and not found -> adding new user
  const [fetchedUser, setFetchedUser] = useState<AdminUser | null>()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const allTags = useTags()
  const saveUser = useEndpoint(saveAdminUser)
  const fetchUser = useEndpoint(getAdminUser)
  const searchUsers = useEndpoint(searchAdminUsers)
  const invalidEmail = emailIsInvalid(email)
  const userIsFetched = user !== undefined || fetchedUser !== undefined
  const userHasRole = roles.isCommentator || roles.isModerator || roles.isOperator
  const currentUser = user ?? fetchedUser

  useEffect(() => {
    setRoles(getRoles(user))
    setUserTags(getTagSet(user))
    setEmail('')
    setFetchedUser(undefined)
  }, [user])

  useEffect(() => {
    if (!open) {
      setRoles(getRoles())
      setUserTags(getTagSet())
      setEmail('')
      setFetchedUser(undefined)
    }
  }, [open])

  useEffect(() => {
    const f = async () => {
      if (!emailIsInvalid(email)) {
        try {
          const user = await fetchUser({ email: email.trim() })
          setFetchedUser(user)
          setRoles(getRoles(user))
          setUserTags(getTagSet(user))
        } catch (e) {
          if (e instanceof NotFoundError) {
            setFetchedUser(null)
            setRoles(getRoles())
            setUserTags(new Set())
          } else {
            throw e
          }
        }
      }
    }
    f()
  }, [email, fetchUser])

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={currentUser ? 'Edit user' : 'Add user'}
      actions={
        <>
          {currentUser && (
            <Button
              onClick={() => setConfirmDialogOpen(true)}
              sx={{ ml: 2 }}
              variant="text"
              color="error"
              size="small"
            >
              Remove user
            </Button>
          )}
          <Button
            onClick={() => {
              saveUser({
                email: user === undefined ? email.trim() : user.externalId,
                roles,
                tags: Array.from(userTags)
              }).then(onSuccess)
            }}
            disabled={!userIsFetched && !userHasRole}
            sx={{ ml: 2 }}
            variant="contained"
            size="small"
          >
            Save user
          </Button>
        </>
      }
    >
      {user === undefined && (
        <UserSearch<string>
          autoSelect
          defaultValue=""
          label="Yle email address"
          errorText={email !== '' && invalidEmail ? 'Input a valid Yle email address' : undefined}
          onSelect={email => setEmail(email || '')}
          onSearch={email => searchUsers({ email })}
          getOptionLabel={value => value}
          renderOption={(props, email) => (
            <li {...props} key={email}>
              {email}
            </li>
          )}
        />
      )}
      {currentUser && <UserDetails user={currentUser} />}
      {currentUser === null && <NewUserDetails email={email} />}
      <Roles roles={roles} setRoles={setRoles} />
      <Tags allTags={allTags} userTags={userTags} setUserTags={setUserTags} />
      {currentUser && (
        <ConfirmDialog
          open={confirmDialogOpen}
          title="Remove user?"
          text={`Are you sure you want to remove user ${currentUser.nick}?`}
          confirmText="Remove user"
          onCancel={() => setConfirmDialogOpen(false)}
          onConfirm={() => {
            setConfirmDialogOpen(false)
            saveUser({
              email: currentUser.externalId,
              roles: {
                isCommentator: false,
                isModerator: false,
                isOperator: false
              },
              tags: [],
              isActive: false
            }).then(onSuccess)
          }}
        />
      )}
    </Modal>
  )
}
